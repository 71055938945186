import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { connect } from 'react-redux'
import TextField from '../../../components/TextField'
import Dropdown from '../../../components/Dropdown'
import CheckboxList from '../../../components/CheckboxList'
import { FilterToggler } from '../FilterToggler'

class ClientsFilter extends PureComponent {
  static propTypes = {
    isDisable: PropTypes.bool,
    agents: PropTypes.array,
    filters: PropTypes.object,
    isManager: PropTypes.bool.isRequired,
    onChangeFilter: PropTypes.func.isRequired,
  }
  static defaultProps = {
    isDisable: false,
    agents: []
  }

  state = { isFixed: false }

  componentDidMount() {
    window.addEventListener('scroll', this.onScroll)
    window.addEventListener('resize', this.onScroll)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScroll)
    window.removeEventListener('resize', this.onScroll)
  }

  getAgentsFilter(agents, agentCompanyId = '') {
    const { filters, onChangeFilter } = this.props
    let active = 0
    const list = agents.reduce((result, item) => {
      if (agentCompanyId === item.id) {
        active = result.length
      }
      const name = typeof item.displayName !== 'undefined' && item.displayName
        ? item.displayName
        : typeof item.shortName !== 'undefined' && item.shortName
          ? item.shortName
          : typeof item.fullName !== 'undefined' && item.fullName
            ? item.fullName
            : undefined
      return result.concat([{ key: item.id, value: name }])
    }, [{ key: 'all', value: 'Все агенты' }])
    if (list.length === 2) {
      active = 1
      if (filters.agentCompanyId !== list[1].key) {
        onChangeFilter({ agentCompanyId: list[1].key })
      }
    }
    return { active, list }
  }

  onScroll = () => {
    if (!this.state.isFixed && window.pageYOffset > 0) {
      this.setState({ isFixed: true })
      return
    }
    if (this.state.isFixed && window.pageYOffset === 0) {
      this.setState({ isFixed: false })
    }
  }

  onSelectDropdown = (name, key) => {
    const { onChangeFilter } = this.props
    if (key === 'all') {
      onChangeFilter({ [`${name}`]: '' })
    } else {
      onChangeFilter({ [`${name}`]: key })
    }
  }

  onTypeText = ({ target }) => this.props.onChangeFilter({ [`${target.name}`]: target.value })

  onClearField = (name, value) => this.props.onChangeFilter({ [`${name}`]: value })

  onCheckboxSelect = ({ target }) => {
    if (target.value === 'all') {
      this.onClearField(target.name, '')
    } else {
      this.props.onChangeFilter({ [`${target.name}`]: target.value })
    }
  }

  render() {
    const { isFixed } = this.state
    const { isDisable, agents, filters, isManager, statusItems, SHOW_CLIENTS_FILTER_STATUS } = this.props

    const agentsFilter = isManager
      ? this.getAgentsFilter(agents, filters.agentCompanyId)
      : null

    return (
      <FilterToggler>
        {({isOpened}) =>
          <div className={classnames('main-filter', {
            'main-filter--fixed': isFixed,
            'main-filter--opened': isOpened,
          })}>
            <div className='main-filter__container'>
              <div className='main-filter__content'>
                <div className={classnames('main-filter__row', { 'main-filter__row--disabled': isDisable })}>
                  {agentsFilter &&
                    <div className='main-filter__control main-filter__control--icon-right'>
                      <Dropdown
                        name='agentCompanyId'
                        toggleClassName='btn btn-dropdown--hidden-border'
                        defaultActive={agentsFilter.active}
                        list={agentsFilter.list}
                        disabled={agentsFilter.list.length < 3}
                        onSelectItem={this.onSelectDropdown}
                      />
                      <i className='icon icon-chevron-down' />
                    </div>
                  }
                  <TextField
                    name='q'
                    placeholder='Клиент'
                    value={filters.q}
                    onChange={this.onTypeText}
                    onClear={this.onClearField}
                  />
                  {/* <div className={classnames('main-filter__control main-filter__control--button')}>
                    <Link className={classnames('btn btn-search')} to='?search'>
                      <i className={classnames('icon icon-seacrh-m')} />
                    </Link>
                  </div> */}
                </div>
                {SHOW_CLIENTS_FILTER_STATUS &&
                  <div className={classnames('main-filter__row', { 'main-filter__row--disabled': isDisable })}>
                    <CheckboxList
                      statusItems={statusItems}
                      activeValue={filters.status}
                      onChangeItem={this.onCheckboxSelect}
                    />
                  </div>
                }
              </div>
            </div>
          </div>
        }
      </FilterToggler>
    )
  }
}

const mapStateToProps = ({ Environment }) => {
  return {
    SHOW_CLIENTS_FILTER_STATUS: Environment?.environment?.REACT_APP_SHOW_CLIENTS_FILTER_STATUS === 'true',
  }
}

export default connect(mapStateToProps)(ClientsFilter)
