import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { createFactoringBlock } from '../../../../redux/Factoring/actions'
import { InputText, Fieldset, Select2, Textarea, RowGrid, Col6, Col12 } from '../../../../layout'
import TextFieldWithAutoComplete from '../../../../components/TextFieldWithAutoComplete'
import { Search } from '../../../../services/api'
import { Button, ButtonWrapper, Form, H3 } from '../../Layout'

export const AddBlockForm = ({
  parsedFilters,
  onGetFactoringView,
  onClose,
}) => {
  const dispatch = useDispatch()
  const { entityType } = useParams()

  const { blockTypes } = useSelector(state => state?.Factoring)
  const { username } = useSelector(state => state?.User)

  const blockTypeOptions = [
    { name: '', value: '' },
    ...blockTypes,
  ]

  const [supplierCompanyName, setSupplierCompanyName] = useState('')
  const [supplierId, setSupplierId] = useState('')
  const [debtorCompanyName, setDebtorCompanyName] = useState('')
  const [debtorId, setDebtorId] = useState('')
  const [factoringContractId, setFactoringContractId] = useState('')
  const [factoringContractNumber, setFactoringContractNumber] = useState('')
  const [supplyContractId, setSupplyContractId] = useState('')
  const [supplyContractNumber, setSupplyContractNumber] = useState('')
  const [supplyId, setSupplyId] = useState('')
  const [supplyNumber, setSupplyNumber] = useState('')
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const [description, setDescription] = useState('')
  const [type, setType] = useState([])
  const [submitted, setSubmitted] = useState(0)

  const payload = {
    userId: username,
    supplierId,
    debtorId,
    factoringContractId,
    supplyContractId,
    supplyId,
    startDate,
    endDate,
    type,
    description,
  }

  const errors = {
    // ...(supplierId?.trim() ? {} : { supplierId: 'Не указан поставщик' }),
    // ...(debtorId?.trim() ? {} : { debtorId: 'Не указан дебитор' }),
    // ...(factoringContractId?.trim() ? {} : { factoringContractId: 'Не указан договор факторинга' }),
    // ...(supplyContractId?.trim() ? {} : { supplyContractId: 'Не указан договор поставки' }),
    // ...(supplyId?.trim() ? {} : { supplyId: 'Не указана поставка' }),
    ...(
      supplierId?.trim() ||
      debtorId?.trim() ||
      factoringContractId?.trim() ||
      supplyContractId?.trim() ||
      supplyId?.trim()
        ? {}
        : {
            mainError: `
              Необходимо указать хотя бы одно из следующих значений:
              <br/> - поставщик
              <br/> - дебитор
              <br/> - договор факторинга
              <br/> - договор поставки
              <br/> - поставка
            `
          }
    ),
    ...(startDate?.trim() ? {} : { startDate: 'Не указана дата начала блокировки' }),
    ...(endDate?.trim() ? {} : { endDate: 'Не указана дата окончания блокировки' }),
    ...(type?.length > 0 ? {} : { type: 'Не выбран тип блокировки' }),
    // ...(description?.trim() ? {} : { description: 'Не указан комментарий' }),
  }

  const isValid = Object.keys(errors)?.length === 0

  const onChangeSupplierId = (payload) => {
    setSupplierId(payload.id)
    setSupplierCompanyName(payload.displayName)
  }
  const onClearSupplierId = () => {
    setSupplierId('')
    setSupplierCompanyName('')
  }

  const onChangeDebtorId = (payload) => {
    setDebtorId(payload.id)
    setDebtorCompanyName(payload.displayName)
  }
  const onClearDebtorId = () => {
    setDebtorId('')
    setDebtorCompanyName('')
  }

  const onChangeFactoringContractId = (payload) => {
    setFactoringContractId(payload.id)
    setFactoringContractNumber(payload.number)
  }
  const onClearFactoringContractId = () => {
    setFactoringContractId('')
    setFactoringContractNumber('')
  }

  const onChangeSupplyContractId = (payload) => {
    setSupplyContractId(payload.id)
    setSupplyContractNumber(payload.number)
  }
  const onClearSupplyContractId = () => {
    setSupplyContractId('')
    setSupplyContractNumber('')
  }

  const onChangeSupplyId = (payload) => {
    setSupplyId(payload.id)
    setSupplyNumber(payload.number)
  }
  const onClearSupplyId = () => {
    setSupplyId('')
    setSupplyNumber('')
  }

  const onChangeStartDate = (value) => setStartDate(value)
  const onChangeEndDate = (value) => setEndDate(value)

  const onChangeType = (e) => setType(e.target.value ? [e.target.value] : [])

  const onChangeDescription = (e) => setDescription(e.target.value)

  const onSubmit = () => setSubmitted(submitted + 1)

  const onCreateFactoringBlock = () => {
    const onSuccess = () => {
      onGetFactoringView(parsedFilters)
      onClose()
    }
    if (isValid) {
      dispatch(createFactoringBlock(payload, entityType, onSuccess))
    }
  }

  useEffect(() => {
    if (submitted > 0) {
      onCreateFactoringBlock()
    }
  // eslint-disable-next-line
  }, [submitted])

  console.log('payload [CURRENT]:', payload)
  console.log('errors', errors)

  return (
    <>
      <H3>Добавить блокировку:</H3>
      <Form>
        <Fieldset>
          <RowGrid>
            <Col12>
              <TextFieldWithAutoComplete
                classNames={{ container: 'filter-input', input: 'filter-input__input' }}
                name='supplierId'
                placeholder='Поставщик'
                label='Поставщик'
                value={supplierCompanyName}
                defaultValue={supplierCompanyName}
                onSelect={onChangeSupplierId}
                onClear={onClearSupplierId}
                payloadKeys={['id', 'displayName']}
                meta={{
                  touched: true,
                  // error: submitted > 0 ? errors.supplierId : '',
                  error: submitted > 0 ? errors.mainError : '',
                }}
                withoutLink
              />
            </Col12>
          </RowGrid>
        </Fieldset>
        <Fieldset>
          <RowGrid>
            <Col12>
              <TextFieldWithAutoComplete
                classNames={{ container: 'filter-input', input: 'filter-input__input' }}
                name='debtorId'
                placeholder='Дебитор'
                label='Дебитор'
                value={debtorCompanyName}
                defaultValue={debtorCompanyName}
                onSelect={onChangeDebtorId}
                onClear={onClearDebtorId}
                payloadKeys={['id', 'displayName']}
                meta={{
                  touched: true,
                  // error: submitted > 0 ? errors.debtorId : '',
                  error: submitted > 0 ? errors.mainError : '',
                }}
                withoutLink
              />
            </Col12>
          </RowGrid>
        </Fieldset>
        <Fieldset>
          <RowGrid>
            <Col12>
              <TextFieldWithAutoComplete
                classNames={{ container: 'filter-input', input: 'filter-input__input' }}
                name='factoringContractId'
                placeholder='Договор факторинга'
                label='Договор факторинга'
                value={factoringContractNumber}
                defaultValue={factoringContractNumber}
                onSelect={onChangeFactoringContractId}
                onClear={onClearFactoringContractId}
                payloadKeys={['id', 'number']}
                request={Search.getFactoringContractSuggestions}
                requestKey='number'
                requestSubKeys={['displayName', 'supplierInn', 'startDate']}
                meta={{
                  touched: true,
                  // error: submitted > 0 ? errors.factoringContractId : '',
                  error: submitted > 0 ? errors.mainError : '',
                }}
                withoutLink
              />
            </Col12>
          </RowGrid>
        </Fieldset>
        <Fieldset>
          <RowGrid>
            <Col12>
              <TextFieldWithAutoComplete
                classNames={{ container: 'filter-input', input: 'filter-input__input' }}
                name='supplyContractId'
                placeholder='Договор поставки'
                label='Договор поставки'
                value={supplyContractNumber}
                defaultValue={supplyContractNumber}
                onSelect={onChangeSupplyContractId}
                onClear={onClearSupplyContractId}
                payloadKeys={['id', 'number']}
                request={Search.getSupplyContractSuggestions}
                requestKey='number'
                requestSubKeys={['sellerName', 'startDate']}
                meta={{
                  touched: true,
                  // error: submitted > 0 ? errors.supplyContractId : '',
                  error: submitted > 0 ? errors.mainError : '',
                }}
                withoutLink
              />
            </Col12>
          </RowGrid>
        </Fieldset>
        <Fieldset>
          <RowGrid>
            <Col12>
              <TextFieldWithAutoComplete
                classNames={{ container: 'filter-input', input: 'filter-input__input' }}
                name='supplyId'
                placeholder='Поставка'
                label='Поставка'
                value={supplyNumber}
                defaultValue={supplyNumber}
                onSelect={onChangeSupplyId}
                onClear={onClearSupplyId}
                payloadKeys={['id', 'supplyContractNumber']}
                request={Search.getSupplySuggestions}
                requestKey='number'
                requestSubKeys={['supplierName', 'supplyDate']}
                meta={{
                  touched: true,
                  // error: submitted > 0 ? errors.supplyId : '',
                  error: submitted > 0 ? errors.mainError : '',
                }}
                withoutLink
              />
            </Col12>
          </RowGrid>
        </Fieldset>
        <Fieldset>
          <RowGrid>
            <Col6>
              <InputText
                type='date' 
                name='startDate'
                label='Дата начала блокировки'
                value={startDate}
                onChange={onChangeStartDate}
                error={submitted > 0 && errors?.startDate}
              />
            </Col6>
            <Col6>
              <InputText
                type='date' 
                name='endDate'
                label='Дата окончания блокировки'
                value={endDate}
                onChange={onChangeEndDate}
                error={submitted > 0 && errors?.endDate}
              />
            </Col6>
          </RowGrid>
        </Fieldset>
        <Fieldset>
          <RowGrid>
            <Col12>
              <Select2
                label='Тип блокировки'
                name='type'
                options={blockTypeOptions}
                value={type}
                onChange={onChangeType}
                error={submitted > 0 && errors.type}
              />
            </Col12>
          </RowGrid>
        </Fieldset>
        <Fieldset>
          <RowGrid>
            <Col12>
              <Textarea
                name='description'
                label='Комментарий'
                value={description}
                onChange={onChangeDescription}
                // error={submitted > 0 && errors.description}
              />
            </Col12>
          </RowGrid>
        </Fieldset>
      </Form>
      <ButtonWrapper>
        <Button
          text='Создать'
          disabled={submitted > 0 && !isValid}
          onClick={onSubmit}
        />
      </ButtonWrapper>
    </>
  )
}