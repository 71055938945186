import React from 'react'
import PropTypes from 'prop-types'
import { TaskCard } from '../../../components/Card/Task'
import Skelet from '../../../components/Card/Skelet'

const TasksList = ({ 
  list, 
  isLoading, 
  isLoadingNext, 
  onOpenDetail, 
  statusItems,
  ismanager,
  isRko,
  agentLogins
}) =>
  <div className='block-list block-list--tasks'>
    {isLoading
      ? [0, 1, 2, 3].map((_, index) => <Skelet key={index} />)
      : list.map((item, index) => (
          <TaskCard
            key={index}
            onOpenDetail={onOpenDetail}
            statusItems={statusItems}
            ismanager={ismanager}
            isRko={isRko}
            agentLogins={agentLogins}
            index={index}
            {...item}
          />
        )
      )
    }
    {isLoadingNext && 
      <Skelet key={list.length + 10} showLoader />
    }
  </div>

TasksList.propTypes = {
  list: PropTypes.array,
  isLoading: PropTypes.bool.isRequired,
  isLoadingNext: PropTypes.bool.isRequired,
  onOpenDetail: PropTypes.func.isRequired,
}

export default TasksList