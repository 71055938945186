import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import TasksFilter from '../../containers/Filter/Tasks'
import TasksRkoFilter from '../../containers/Filter/TasksRko'
import TasksList from '../../containers/List/Tasks'
import EmptyTasksList from '../../components/Empty/TasksList'

import {
  getTasksList,
  getNextTasksPage,
  setTasksFilter,
  clearAllFilters,
  setTasksFilterHard
} from '../../redux/Tasks/actions'
import { authenticationUser } from '../../redux/User/actions'
import { prepareOrderStatusCategory } from '../../utils/prepareOrderStatusCategory'

class Tasks extends PureComponent {
  static propTypes = {
    isFetching: PropTypes.bool.isRequired,
    isFetchingNext: PropTypes.bool.isRequired,
    list: PropTypes.array,
    filters: PropTypes.object,
    filterAmount: PropTypes.shape({
      from: PropTypes.number.isRequired,
      to: PropTypes.number.isRequired
    }).isRequired,
    nextPage: PropTypes.number.isRequired,
    hasMorePage: PropTypes.bool.isRequired,
    processDefinitionKeys: PropTypes.array.isRequired,
    dispatch: PropTypes.func.isRequired
  }

  componentDidMount() {
    const { filters, dispatch } = this.props

    dispatch(getTasksList(filters))
    window.addEventListener('scroll', this.onScroll)
    window.addEventListener('resize', this.onScroll)
  }

  componentWillReceiveProps(nextProps) {
    const { filters, dispatch } = this.props
    if (JSON.stringify(filters) !== JSON.stringify(nextProps.filters)) {
      dispatch(getTasksList(nextProps.filters))
    }
  }

  componentWillUnmount() {
    const { dispatch } = this.props
    dispatch(clearAllFilters())
    window.removeEventListener('scroll', this.onScroll)
    window.removeEventListener('resize', this.onScroll)
  }

  onScroll = () => {
    if (this.props.isRko) {
      return
    }

    const {
      list,
      filters,
      isFetchingNext,
      nextPage,
      hasMorePage,
      dispatch,
    } = this.props

    const container = document.querySelector('.block-list.block-list--tasks')

    if (!container) {
      return null
    }

    const { height } = container.getBoundingClientRect()

    if (
        !isFetchingNext &&
        list.length > 0 &&
        hasMorePage &&
        window.pageYOffset + window.innerHeight + 10 >= height
    ) {
      dispatch(getNextTasksPage(nextPage, filters))
    }
  }

  onChangeFilter = filters => {
    const { isRko } = this.props

    if (!isRko) {
      this.props.dispatch(setTasksFilter(filters))
    } else {
      this.props.dispatch(setTasksFilterHard(filters))
    }
  }

  onOpenDetail = (taskId, taskName) => {
    const { history, dispatch } = this.props
    dispatch(authenticationUser(true))
      .then(() => history.push(`/tasks/${taskId}`, { title: taskName }))
      .catch(err => console.log(err))
  }

  render() {
    const {
      agentLogins,
      list,
      filters,
      filterAmount,
      processDefinitionKeys,
      isFetching,
      isFetchingNext,
      settings,
      ismanager,
      isRko,
      defaultClientValue,
      orderStatusCategory
    } = this.props

    const statusItems = prepareOrderStatusCategory({
      statusItems: settings.statusItems,
      orderStatusCategory,
    })

    const emptyTasksListMessage = !isRko
      ? undefined
      : !filters.inn
        ? 'Требуется указать ИНН для поиска заявок'
        : !filters.phone && !filters.email
          ? 'Требуется указать E-mail или телефон для поиска заявок'
          : undefined


    return (
      <section className='fr-content fr-content--tasks'>
        {isRko
          ? <TasksRkoFilter
              isDisable={!list.length && !Object.keys(filters).length}
              filters={filters}
              filterAmount={filterAmount}
              processes={processDefinitionKeys}
              onChangeFilter={this.onChangeFilter}
              statusItems={statusItems}
              defaultClientValue={defaultClientValue}
            />
          : <TasksFilter
              isDisable={!list.length && !Object.keys(filters).length}
              filters={filters}
              filterAmount={filterAmount}
              processes={processDefinitionKeys}
              onChangeFilter={this.onChangeFilter}
              statusItems={statusItems}
            />
        }
        {!list.length && !isFetching
          ? <EmptyTasksList message={emptyTasksListMessage} />
          : <TasksList
              list={list}
              isLoading={isFetching}
              isLoadingNext={isFetchingNext}
              statusItems={statusItems}
              onOpenDetail={this.onOpenDetail}
              ismanager={ismanager}
              isRko={isRko}
              agentLogins={agentLogins}
            />
        }
      </section>
    )
  }
}

const mapStateToProps = ({ Company, Tasks, User, Settings }) => {
  return {
    agentLogins: Settings?.company?.agentLogins,
    isFetching: Tasks.isFetching,
    isFetchingNext: Tasks.isFetchingNext,
    list: Tasks.order,
    filters: {
      ...Tasks.filters,
      orderStatusCategory: Settings.orderStatusCategory,
    },
    nextPage: Tasks.page + 1,
    hasMorePage: Tasks.more,
    processDefinitionKeys: User.processDefinitionKeys,
    filterAmount: {
      from: Tasks.amount_min,
      to: Tasks.amount_max,
    },
    settings: User.settings,
    ismanager:
      Settings &&
      Settings.user &&
      Settings.user.ismanager,
    isRko: User.isrko,
    orderStatusCategory: Settings.orderStatusCategory,
    defaultClientValue: Tasks.clientName,
  }
}

export default connect(mapStateToProps)(Tasks)