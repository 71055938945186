import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { getActiveTask } from '../../../redux/Tasks/selectors'
import { getNextTask } from '../../../services/api/tasks'
import { insertTask, setActiveTask } from '../../../redux/Tasks/actions'
import Overlay from '../../../components/Overlay'
import { isPressEscape } from '../../../utils'

class TaskDetail extends PureComponent {
  static propTypes = {
    id: PropTypes.string.isRequired,
    processDefinitionKeys: PropTypes.string,
    isFetching: PropTypes.bool.isRequired,
    title: PropTypes.string,
    onCloseDetail: PropTypes.func.isRequired,
  }
  static defaultProps = {
    processDefinitionKeys: '',
    title: '<i style="font-style: italic; color: #ccc;">Название заявки</i>',
  }

  state = { 
    loading: false, 
  }

  static getFormScript(id, processDefinitionKeys) {
    return `
      try {
        ngapp.init($('#task-detail')[0], '#process_definition_key#', function (CamSDK) {
          var urlPrefix = '',
            camClient = new CamSDK.Client({
              mock: false,
              apiUri: '/camunda/api/engine'
            }),
            taskService = new camClient.resource('task'),
            $container  = $('#task').parent();;
            
          function openForm(taskData) {
            $('#task-detail').data('taskData', taskData);
  
            taskService.form(taskData.id, function (err, taskFormInfo) {
        
              var url = urlPrefix + taskFormInfo.key.replace('embedded:app:', taskFormInfo.contextPath + '/');
        
              new CamSDK.Form({
                client: camClient,
                formUrl: url,
                taskId: taskData.id,
                containerElement: $('#camunda'),
                done: function (err, camForm) {
                  if (err) {
                    console.log('all', err);
                    throw err;
                  }
        
                  camForm.on('submit-success', function () {
                    removePreloader();
                    window['submitSuccess-#task_id#'] && window['submitSuccess-#task_id#']()
                    delete window['submitSuccess-#task_id#']
                  });
        
                  camForm.on('submit-error', function (evt, res) {
                    removePreloader();
                    setErrorNotification(res[0]);
                    setTimeout(function () {
                      clearErrorNotification();
                    }, 3000);
                  });
        
                  $('#camunda_complete').off().on("click", function (e) {
                    e.preventDefault()
                    setPreloader();
                    camForm.submit(function (err) {
                      if (err) {
                        removePreloader();
                        setErrorNotification(err);
                        setTimeout(function () {
                          clearErrorNotification();
                        }, 3000);                
                        throw err;
                      }
                      else {
                        //логируем успешное выполнение завершения задачи
                        $.post('/api/task/log_task_completion',
                          {
                            task_id: taskData.id
                          },
                          function (res) {
                            //обработка ошибки логирования
                            if (res.status == 'error') {
                              //$container.removeOverlay();
                              var $scope = angular.element('.start-form-section form').scope();
                              if ($scope.$$camForm.$valid) {
                                //uas.flash.error('Ошибка логирования');
                                throw err;
                              }
                            }
                          });
                      }
                    });
                  });
                }
              });
            });
          }
            
          function getTask(taskId) {
            taskService.get(taskId, function (err, res) {
              if (err) {
                console.log(err, err.message, err.response);
                if (err.status === 404) {
                  $('#camunda').html(
                    '<div class="error-code error-404"> ' +
                    '<i>404</i> ' +
                    '<h1>Задача не найдена</h1> ' +
                    '<p>Запрашиваемая вами задача не найдена.</p> ' +
                    '</div>'
                  );
                  return;
                }
                if (err.status === 401) {
                  $('#camunda').html(
                    '<div class="error-code error-401"> ' +
                    '<i>401</i> ' +
                    '<h1>Ошибка авторизации</h1> ' +
                    '<p>Произошла ошибка при попытке авторизации.</p> ' +
                    '</div>'
                  );
                  return;
                }
                $('#camunda').html(
                  '<div class="error-code"> ' +
                  '<i>Assign me</i> ' +
                  '<h1>Assign me</h1> ' +
                  '<p>Мы сожалеем, но что-то пошло не так.</p> ' +
                  '</div>'
                );
                return;
              }
              
              openForm(res);
            });
          }
          
          function setErrorNotification(content) {
            var innerHtml =
              '<span class="notification__text">' +
              content +
              '</span>' +
              '<button type="button" class="notification__reload-link">' +
              '    <i class="icon icon-close-s" />' +
              '</button>'
              .replace(/#content#/g, content);
            
            var elem = $(document.createElement('div'))
              .addClass('notification')
              .attr('id', 'error-note')
              .html(innerHtml);
              
            $('#root').append(elem);
            
            $('.notification__reload-link').click(function() {
              clearErrorNotification();
            });
          }
          
          function clearErrorNotification() {
            $('#error-note').remove();
          }
        
          function setPreloader() {
            var innerHtml = 
              '<svg viewBox="-2000 -1000 4000 2000">' +
                '<path id="inf" d="M354-354A500 500 0 1 1 354 354L-354-354A500 500 0 1 0-354 354z" />' +
                '<use xlink:href="#inf" stroke-dasharray="1570 5143" stroke-dashoffset="6713px" />' +
              '</svg>';
              
            var elem = $(document.createElement('div'))
              .addClass('preloader preloader--big')
              .attr('id', 'preloader')
              .html(innerHtml);
              
            $('#root').append(elem);
          }
          
          function removePreloader() {
            $('#preloader').remove();
          }
          
          getTask('#task_id#');
        });
      } catch (err) {
        console.log(err);
      }
    `
      .replace(/#process_definition_key#/g, processDefinitionKeys)
      .replace(/#task_id#/g, id);
  }

  componentDidMount() {
    this.initNgApp()
    document.addEventListener('keyup', this.onEscape, false)
  }

  componentWillUnmount() {
    document.removeEventListener('keyup', this.onEscape, false)
  }

  componentDidUpdate(prevProps) {
    if (prevProps.id !== this.props.id) {
      this.initNgApp()
    }
  }

  onEscape = (e) => {
    if (isPressEscape(e)) {
      const modalCloseElements = document.getElementsByClassName('fz-modal__button-close')
      if (modalCloseElements.length > 0) {
        // modalCloseElements[0].click()
      } else {
        this.props.onCloseDetail()
      }
    }
  }

  initNgApp() {
    const { id, processDefinitionKeys, onCloseDetail } = this.props
    window[`submitSuccess-${id}`] = this.onFormSubmitSuccess.bind(this)
    //return
    if (processDefinitionKeys.length === 0) {
      onCloseDetail()
    }

    const script = document.createElement('script')
    script.type = 'text/javascript'
    script.async = true
    script.innerHTML = TaskDetail.getFormScript(id, processDefinitionKeys)

    const taskDetailBlock = document.querySelector('#task-detail')
    taskDetailBlock.appendChild(script)
  }

  onCloseAndRefresh = () => {
    const { onCloseDetail, onRefreshTasksList } = this.props
    onRefreshTasksList()
    onCloseDetail()
  }

  onCompleteTask = (e) => {
    const { id, onRefreshTasksList } = this.props
    if (!e.defaultPrevented) {
      window[`submitSuccess-${id}`] && window[`submitSuccess-${id}`]()
      delete window[`submitSuccess-${id}`]
      onRefreshTasksList()
    }
  }

  onFormSubmitSuccess() {
    const { activeTask } = this.props
    if (!(activeTask && activeTask.isWizzard)) {
      this.onCloseAndRefresh()
    } else {
      this.waitNextTask()
    }
  }

  waitNextTask = async (iteration = 0) => {
    const { 
      dispatch, 
      settings, 
      history, 
      // onCloseDetail, 
    } = this.props
    this.setState({ loading: true })
    const nextTask = await this.getNextTask()
    if (nextTask) {
      dispatch(insertTask(nextTask))
      const nextTaskId = nextTask.task_id || nextTask.id
      dispatch(setActiveTask(nextTaskId))
      this.setState({ loading: false })
      //this.initNgApp()
      history.replace(`/tasks/${nextTaskId}`, { title: nextTask.name })
    } else {
      if ((iteration * settings.poolingInterval) < settings.poolingMaxTime && nextTask === undefined) {
        setTimeout(() => { 
          this.waitNextTask(iteration + 1) 
        }, settings.poolingInterval * 1000)
      } else {
        this.setState({ 
          loading: false, 
          hasNotNextTask: true, 
        })
      }
    }
  }

  getNextTask = async () => {
    const { activeTask = {}, dispatch } = this.props
    try {
      const { isSuccess, ...resp } = await getNextTask(activeTask.task_id)
      if (!isSuccess) {
        return null
      }
      if (!resp.nextTask) {
        this.setState({ wizzardNoNextText: resp.wizzardNoNextText })
        if (!resp.wizzardNoNextText) {
          this.onCloseAndRefresh()
        }
      }
      return resp.nextTask
    } catch (err) {
      dispatch(Error.setErrorContent(err))
      return null
    }
  }

  render() {
    const { title, activeTask = {} } = this.props
    const { loading, hasNotNextTask, wizzardNoNextText } = this.state

    return (
      <>
        <div className='modal-content__header'>
          <div>
            <div className='modal-content__title modal-content__title--task'>
              <span className='icon icon-ok'/>
              <span
                className='task-title'
                dangerouslySetInnerHTML={{ __html: activeTask.title || title }}
              />
            </div>
          </div>
        </div>
        <div className='modal-content__body' id='task-detail'>
          {(loading && !hasNotNextTask)
            ? <Overlay/> 
            : !hasNotNextTask
              ? <div id='camunda' >
                  <div className='loading-indicator'>Подождите, идет загрузка...</div>
                </div> 
              : <div>
                  <div className='loading-indicator'>{wizzardNoNextText  || 'Нет следующей задачи'}</div>
                </div>
          }
        </div>
        <div className='modal-content__footer'>
          {!hasNotNextTask 
            ? <>
                <button className='btn btn-primary' type='button' onClick={this.onCloseAndRefresh}>Отменить</button>
                <button className='btn btn-primary' type='button' onClick={this.onCompleteTask} id='camunda_complete'>Завершить</button>
              </> 
            : <button className='btn btn-primary' type='button' onClick={this.onCloseAndRefresh}>Закрыть</button>
          }
        </div>
      </>
    )
  }
}

const getProcessDefinitioinKey =  (user) => {
  return (
    user && 
    user.processDefinitionKeys[0] && 
    user.processDefinitionKeys[0].process_definition_key
  ) || undefined
}

const mapStateToProps = ({ Tasks, User, Settings }, ownProps) => {
  return {
    activeTask: getActiveTask(Tasks, ownProps.id || Tasks.activeTaskId),
    isFetching: Tasks.isFetching,
    title: ownProps.title || Tasks.tasks[ownProps.id],
    processDefinitionKeys: getProcessDefinitioinKey(User),
    settings: Settings.nextTask || {},
  }
}

export default withRouter(connect(mapStateToProps)(TaskDetail))