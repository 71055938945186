import ReactGitInfo from 'react-git-info/macro'
import Cookies from 'js-cookie'
import { version } from '../package.json'

const REACT_GIT_INFO = ReactGitInfo()

export const JWT = Cookies.get('JWT')

export const ENV = process?.env?.REACT_APP_ENV
export const BUILD_ID = process?.env?.REACT_APP_BUILD_ID

export const THEME_COLOR = process?.env?.REACT_APP_THEME_COLOR || '#242232'
export const IS_NEW_AUTH = process?.env?.REACT_APP_NEW_AUTH === 'true'
export const IS_SECURED_JWT = process?.env?.REACT_APP_IS_SECURED_JWT === 'true'
export const REGISTER_ERROR_MESSAGE = process?.env?.REACT_APP_REGISTER_ERROR_MESSAGE || ''
export const KEYCLOAK_AUTH_TYPE = process?.env?.REACT_APP_KEYCLOAK_TYPE || 'check-sso'

console.info(`REACT_APP_ENV - ${ENV}`)
console.info(`REACT_APP_BUILD_ID - ${BUILD_ID}`)
console.info(`REACT_APP_VERSION - ${version}`)
console.info(`REACT_GIT_INFO - `, REACT_GIT_INFO)

export const IS_PROD = ENV === 'prod'
export const IS_DEV = ENV === 'dev'

export const IS_DEFAULT = BUILD_ID === undefined
export const IS_PEAR = BUILD_ID === 'pear'
export const IS_PEAR_LEASING = BUILD_ID === 'pear-leasing'
export const IS_PAPAYA = BUILD_ID === 'papaya'
export const IS_POMELO = BUILD_ID === 'pomelo'
export const IS_RAMBUTAN = BUILD_ID === 'rambutan'
export const IS_CHERRY = BUILD_ID === 'cherry'
export const IS_GUAVA = BUILD_ID === 'guava'
export const IS_LIME = BUILD_ID === 'lime'
export const _IS_COCONUT = BUILD_ID === 'coconut'
export const IS_COCONUT_FACTORING = BUILD_ID === 'coconut-factoring'
export const IS_COCONUT = _IS_COCONUT || IS_COCONUT_FACTORING

export const COLOR_KEYS = {
  purple: 'purple',
  blue: 'blue',
  yellow: 'yellow',
  red: 'red',
  green: 'green'
}

export const COLOR_VALUES = {
  purple: '#722ED1',
  blue: '#538DD5',
  yellow: '#E98C0D',
  red: '#FF0D19',
  green: '#41B807'
}

export const COLOR_VALUES_MIDDLE = {
  purple: '#D3ADF7',
  blue: '#ADC6FF',
  yellow: '#E9CA66',
  red: '#F27C75',
  green: '#ACE97D'
}

export const COLOR_VALUES_LIGHT = {
  purple: '#F9F0FF',
  blue: '#F0F5FF',
  yellow: '#FFFBE6',
  red: '#FFEFEE',
  green: '#F4FFEA'
}

export const SETTINGS = {
  authType: process?.env?.REACT_APP_AUTHTYPE || 'keycloak',
  PHONE: '+7 (495) 799–09–83',
  EMAIL: 'support@farzoom.com',
  COPYRIGHT: 'FarZoom #ACTUAL_DATE# © v. 1.2.9.1 ßeta 2',
  statusItems: [
    { key: 'total', text: 'Всего' },
    { key: 'assigned', text: 'К выполнению', className: COLOR_KEYS.purple, color: COLOR_VALUES.purple },
    { key: 'inprogress', text: 'На стороне банка', className: COLOR_KEYS.yellow, color: COLOR_VALUES.yellow },
    { key: 'lost', text: 'Отказано', className: COLOR_KEYS.red, color: COLOR_VALUES.red },
    { key: 'sold', text: 'Одобрено', className: COLOR_KEYS.green, color: COLOR_VALUES.green }
  ]
}

export const COLORS_BY_ORDER_STATUS_CATEGORY = {
  total: '',
  assigned: COLOR_KEYS.purple,
  assignedAgent: COLOR_KEYS.purple,
  assignedClient: COLOR_KEYS.blue,
  inprogress: COLOR_KEYS.yellow,
  sold: COLOR_KEYS.green,
  lost: COLOR_KEYS.red
}

export const adaptive_1200 = '@media (max-width: 1199.98px)'
export const adaptive_1400 = '@media (max-width: 1400px)'

export const FACTORING_VIEWS = [
  { entityType: 'counterparty', title: 'Контрагенты', entityName: 'контрагента' },
  { entityType: 'factoring_contract', title: 'Договоры', entityName: 'договора' },
  { entityType: 'supply_contract', title: 'Контракты', entityName: 'контракта' },
  { entityType: 'financing', title: 'Финансирования', entityName: 'финансирования' },
  { entityType: 'rate', title: 'Ставки', entityName: 'ставки' },
  { entityType: 'block', title: 'Блокировки', entityName: 'блокировки' },
  { entityType: 'limit', title: 'Лимиты', entityName: 'лимита' },
  { entityType: 'supply', title: 'Поставки', entityName: 'поставки' },
  { entityType: 'operation', title: 'Проводки', entityName: 'проводки' },
  { entityType: 'product', title: 'Тарифы', entityName: 'тарифа' },
  { entityType: 'order', title: 'Ожидание оплаты', entityName: 'ожидания оплаты' },
  { entityType: 'account', title: 'Счета', entityName: 'счёта' },
  { entityType: 'payment', title: 'Платежи', entityName: 'платежа' },
  { entityType: 'reserve_msfo', title: 'Резервы МСФО', entityName: 'резерва МСФО' },
  { entityType: 'reserve_rsbu', title: 'Резервы РСБУ', entityName: 'резерва РСБУ' },
  { entityType: 'notification', title: 'Уведомления', entityName: 'уведомления' },
  { entityType: 'supply_registry', title: 'Реестры поставок', entityName: 'Реестры поставок' },
]
