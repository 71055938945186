import React, { Fragment } from 'react'
import Helmet from 'react-helmet'
import './default.css'

export default ({ children }) => 
  <Fragment>
    <Helmet>
      <title>Кабинет агента</title>
      <link id="favicon" rel="shortcut icon" href={require('./favicon.ico')} />
    </Helmet>
    {children}
  </Fragment>