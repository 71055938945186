import React, { PureComponent } from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import ClientsFilter from '../../containers/Filter/Clients'
import ClientsList from '../../containers/List/Clients'
import ClientsStatsPanel from '../../components/StatsPanel/Clients'
import EmptyClientsList from '../../components/Empty/ClientsList'
import {
  getClientsList,
  getNextClientsList,
  setClientsFilter,
  clearAllFilters,
} from '../../redux/Clients/actions'
import { getAgentsList } from '../../redux/Agents/actions'
import { Redirect } from 'react-router-dom'

class Clients extends PureComponent {
  static propTypes = {
    isFetching: PropTypes.bool.isRequired,
    isFetchingNext: PropTypes.bool.isRequired,
    agents: PropTypes.array,
    company: PropTypes.array,
    filters: PropTypes.object,
    clientsCount: PropTypes.number,
    nextPage: PropTypes.number,
    hasMorePage: PropTypes.bool,
    dispatch: PropTypes.func.isRequired,
  }

  componentDidMount() {
    const { isManager, filters, dispatch } = this.props
    dispatch(getClientsList(filters))
    if (isManager) {
      dispatch(getAgentsList())
    }
    window.addEventListener('scroll', this.onScroll)
    window.addEventListener('resize', this.onScroll)
  }

  componentWillReceiveProps(nextProps) {
    const { filters, dispatch } = this.props
    if (JSON.stringify(filters) !== JSON.stringify(nextProps.filters)) {
      dispatch(getClientsList(nextProps.filters))
    }
  }

  componentWillUnmount() {
    const { dispatch } = this.props
    dispatch(clearAllFilters())
    window.removeEventListener('scroll', this.onScroll)
    window.removeEventListener('resize', this.onScroll)
  }

  onScroll = () => {
    const {
      company,
      isFetchingNext,
      filters,
      nextPage,
      hasMorePage,
      dispatch,
    } = this.props
    const container = document.querySelector('.block-list.block-list--clients')
    if (!container) {
      return null
    }
    const { height } = container.getBoundingClientRect()
    if (
      !isFetchingNext && 
      company.length > 0 && 
      hasMorePage &&
      window.pageYOffset + window.innerHeight + 10 >= height
    ) {
      dispatch(getNextClientsList(nextPage, filters))
    }
  }

  onChangeFilter = (filters) => {
    this.props.dispatch(setClientsFilter(filters))
  }

  render() {
    const {
      agents,
      company,
      stat,
      isManager,
      isFetching,
      isFetchingNext,
      filters,
      settings,
      noClients,
      SHOW_CLIENTS_FILTER_STATUS,
    } = this.props

    const showClientsStatsPanel = stat && stat.company && !isFetching

    return noClients
      ? <Redirect to='/tasks' />
      : <section className={classnames(
        'fr-content',
        'fr-content--clients',
        {
          'fr-content--clientsCustom': SHOW_CLIENTS_FILTER_STATUS && showClientsStatsPanel,
          'fr-content--clientsCustom2': !SHOW_CLIENTS_FILTER_STATUS || !showClientsStatsPanel,
        }
      )}>
        <ClientsFilter
          isDisable={!company.length && !Object.keys(filters).length}
          agents={agents}
          filters={filters}
          isManager={isManager}
          onChangeFilter={this.onChangeFilter}
          statusItems={settings.statusItems}
        />
        {!company.length && !isFetching
          ? <EmptyClientsList />
          : <>
              {showClientsStatsPanel &&
                <ClientsStatsPanel
                  companiesStat={stat.company}
                  statusItems={settings.statusItems}
                />
              }
              <ClientsList
                list={company}
                isLoading={isFetching}
                isLoadingNext={isFetchingNext}
                statusItems={settings.statusItems}
              />
            </>
          }
      </section>
  }
}

const mapStateToProps = ({
  Agents,
  Clients,
  User,
  Environment,
}) => {
  return {
    isManager: User.ismanager,
    isFetching: Clients.isFetching,
    isFetchingNext: Clients.isFetchingNext,
    company: Clients.company,
    filters: Clients.filters,
    stat: Clients.stat,
    nextPage: Clients.page + 1,
    hasMorePage: Clients.more,
    agents: Agents.agents,
    settings: User.settings,
    noClients: User.noClients,
    SHOW_CLIENTS_FILTER_STATUS: Environment?.environment?.REACT_APP_SHOW_CLIENTS_FILTER_STATUS === 'true',
  }
}

export default connect(mapStateToProps)(Clients)