import React from 'react'
import { createUseStyles } from 'react-jss'
import { Icon } from '../../../../../layout'
import { formatNumber } from '../../../../../utils'
import { Copy } from '../../../../../components/Copy/Copy'

const useStyles = createUseStyles({
  root: {
    background: '#fff',
    margin: [0, 20],
    padding: [14, 10],
    borderBottom: [1, 'solid', '#eee'],
    position: 'sticky',
    top: 0,
    zIndex: 3,
  },
  wrapper: {
    paddingRight: 240,
    position: 'relative',
  },
  fullName: {
    color: '#b8b8b8',
    fontSize: 10,
    lineHeight: 18/10,
    fontWeight: 500,
    letterSpacing: 1,
    textTransform: 'uppercase',
  },
  displayName: {
    marginTop: 4,
    color: '#504E5B',
    fontSize: 26,
    lineHeight: 32/26,
    fontWeight: 600,
  },
  inn: {
    marginTop: 8,
    color: '#000',
    fontSize: 15,
    lineHeight: 22/15,
    fontWeight: 500,
    display: 'inline-block'
  },
  items: {
    position: 'absolute',
    bottom: 0,
    right: 0,
  },
  item: {
    display: 'inline-block',
    verticalAlign: 'top',
    '& + &': {
      marginLeft: 30,
    },
  },
  label: {
    color: '#000',
    fontSize: 10,
    lineHeight: 15/10,
    fontWeight: 500,
    letterSpacing: 1,
    opacity: 0.5,
  },
  value: {
    color: '#000',
    fontSize: 15,
    lineHeight: 22/15,
    fontWeight: 500,
    display: 'inline-block'
  },
  stats: {
    position: 'absolute',
    top: 2,
    right: 0,
  },
  stat: {
    display: 'inline-block',
    verticalAlign: 'top',
    '& + &': {
      marginLeft: 16,
    },
    '& > svg': {
      width: 14,
      height: 14,
      marginRight: 8,
      display: 'inline-block',
      verticalAlign: 'top',
    },
    '& > span': {
      fontSize: 11,
      lineHeight: 14/11,
      fontWeight: 600,
      display: 'inline-block',
      verticalAlign: 'top',
    },
  },
})

export const Header = ({
  fullName,
  displayName,
  INN,
  KPP,
  OGRN,
  statusItems,
  stats,
}) => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <div className={classes.wrapper}>
        <div className={classes.fullName}>{fullName}</div>
        <div className={classes.displayName}>{displayName}</div>
        <Copy valueToCopy={INN} successText={'ИНН скопирован'}><div className={classes.inn}>ИНН {INN}</div></Copy>
        <div className={classes.stats}>
          {statusItems.map(({ key, text, color }) => key === 'total'
          ? null
          : stats && stats[key]?.count
              ? <div className={classes.stat} title={text} style={{ color }} key={key}>
                  <Icon iconName='check' style={{ 'fill': color }} />
                  <span>{formatNumber(stats[key].count)}</span>
                </div>
              : null
          )}
        </div>
        <div className={classes.items}>
          <div className={classes.item}>
            <div className={classes.label}>КПП</div>
            <Copy valueToCopy={KPP} successText={'КПП скопирован'}>
              <div className={classes.value}>{KPP}</div>
            </Copy>
          </div>
          <div className={classes.item}>
            <div className={classes.label}>ОГРН</div>
            <Copy valueToCopy={OGRN} successText={'ОГРН скопирован'}>
              <div className={classes.value}>{OGRN}</div>
            </Copy>
          </div>
        </div>
      </div>
    </div>
  )
}