import * as actionTypes from './actionTypes'

const initialState = {
  environment: {
    REACT_APP_SHOW_CHANGE_PASSWORD: process?.env?.REACT_APP_SHOW_CHANGE_PASSWORD,
    REACT_APP_SIGNERS_DOCS_URL: process?.env?.REACT_APP_SIGNERS_DOCS_URL,
    REACT_APP_BANK_EMPLOYEE_CABINET_URL: process?.env?.REACT_APP_BANK_EMPLOYEE_CABINET_URL,
    REACT_APP_SHOW_STATS: process?.env?.REACT_APP_SHOW_STATS,
    REACT_APP_SHOW_BANNER: process?.env?.REACT_APP_SHOW_BANNER,
    REACT_APP_SHOW_TASKS_FILTER_SUMM: process?.env?.REACT_APP_SHOW_TASKS_FILTER_SUMM,
    REACT_APP_SHOW_TASKS_FILTER_CLIENT: process?.env?.REACT_APP_SHOW_TASKS_FILTER_CLIENT,
    REACT_APP_SHOW_CLIENTS_FILTER_STATUS: process?.env?.REACT_APP_SHOW_CLIENTS_FILTER_STATUS,
    REACT_APP_SHOW_ISSUE_BEFORE: process?.env?.REACT_APP_SHOW_ISSUE_BEFORE,
    REACT_APP_SHOW_ADDITIONAL_GROUPS: process?.env?.REACT_APP_SHOW_ADDITIONAL_GROUPS,
    REACT_APP_SHOW_COMMENTS: process?.env?.REACT_APP_SHOW_COMMENTS,
    REACT_APP_LANDING_DADATA: process?.env?.REACT_APP_LANDING_DADATA,
    REACT_APP_FACTORING_ENABLED: process?.env?.REACT_APP_FACTORING_ENABLED,
    REACT_APP_SHOW_REPORTS: process?.env?.REACT_APP_SHOW_REPORTS,
  },
  isError: false,
  isFetching: false,
  isLoaded: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_ENVIRONMENT:
      return {
        ...state,
        isError: false,
        isFetching: true,
      }
    case actionTypes.GET_ENVIRONMENT_SUCCESS:
      return {
        ...state,
        environment: {
          ...state.environment,
          ...action.data,
        },
        isError: false,
        isFetching: false,
        isLoaded: true,
      }
    case actionTypes.GET_ENVIRONMENT_ERROR:
      return {
        ...state,
        isError: true,
        isFetching: false,
      }
    default:
      return state
  }
}