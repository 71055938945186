import React, { useEffect } from 'react'
import classnames from 'classnames'
import injectSheet from 'react-jss'
import { useSelector } from 'react-redux'
import { Field, reduxForm, change, reset } from 'redux-form'
import Overlay from '../../../components/Overlay'
import { FormCheckboxField } from '../../../components/_formFields/FormCheckbox'
import { FormInputPhone } from '../../../components/_formFields/FormInputPhone'
import { FormTextField } from '../../../components/_formFields/FormTextField'
import { isPhone } from '../../../components/InputPhone/PhoneNumberProps'
import Item from '../../Verify/Item'
import Content from '../../Verify/Content'
import { isINN } from '../../../utils/isINN'
import { isOGRN } from '../../../utils/isOGRN'
import regexps from '../../../utils/regexps'
import { THEME_COLOR, IS_COCONUT } from '../../../config'
import { HELPER_TEXT_1, HELPER_TEXT_2 } from '../index'
import { InputWithDadata } from '../../../layout'

const styles = {
  row: {
    marginTop: 16,
    display: 'flex',
    '& > *': {
      flex: 1,
    },
    '& > div + div': {
      marginLeft: 16,
    },
  },
  submitButton: {
    height: 'auto',
    marginTop: 16,
    padding: [8, 20],
    lineHeight: 32/16,
    outline: 'none',
    backgroundColor: THEME_COLOR,
    color: '#fff',
    borderRadius: `16px !important`,
    '&:hover': {
      boxShadow: [0, 2, 4, '#ccc'],
    },
    '&:active': {
      backgroundColor: '#EB9A42 !important',
      filter: `brightness(0.95)`,
    },
    '&:focus': {
      boxShadow: 'none',
    },
  },
  small: {
    marginTop: 16,
    fontSize: 12,
    lineHeight: 1.5,
  },
}

const DEFAULT_VALUES = {
  fullName: '',
  // inn: '132808730606',
  inn: '',
  ogrn: '',
  lastName: '',
  firstName: '',
  secondName: '',
  email: '',
  phone: '',
  vatPayer: false,
}

const HELPER_ID = 'helper'
const REGISTER_FORM = 'register'
const REGISTER_FORM_ID = 'register-form'
const REGISTER_FORM_SETTINGS = {
  form: REGISTER_FORM,
  onSubmitSuccess: (result, dispatch) => {
    const form = document.getElementById(REGISTER_FORM_ID)
    const helper = document.getElementById(HELPER_ID)
    form.reset()
    if (helper && helper.innerHTML) { 
      helper.innerHTML = `${HELPER_TEXT_1}${HELPER_TEXT_2}`
    }
    return dispatch(reset(REGISTER_FORM))
  },
}

const ERRORS = {
  required: 'Поле обязательно для заполнения!',
  phone: 'Введен некорректный телефонный номер!',
  email: 'Введен некорректный e-mail!',
  inn: 'Введен некорректный ИНН!',
  ogrn: 'Введен некорректный ОГРН/ОГРНИП!',
  password: 'Пароль должен содержать не менее 6 символов, включая строчные и прописные латинские буквы, цифры и спецсимволы!',
  passwordConfirm: 'Пароль не совпадает!',
}

const VALIDATIONS = {
  required: (value) => value ? '' : ERRORS.required,
  phone: (value) => isPhone(value) ? '' : ERRORS.phone,
  email: (value) => regexps.email.test(value) ? '' : ERRORS.email,
  inn: (value) => isINN(value, {}) ? '' : ERRORS.inn,
  ogrn: (value) => isOGRN(value, {}) ? '' : ERRORS.ogrn,
  password: (value) => regexps.password.test(value) ? '' : ERRORS.password,
  passwordConfirm: (equals) => equals ? '' : ERRORS.passwordConfirm,
}

const Form = ({
  classes,
  initialize,
  data,
  handleSubmit,
  dispatch,
}) => {
  const _data = useSelector(state => state.form[REGISTER_FORM])
  const { REACT_APP_LANDING_DADATA } = useSelector(state => state?.Environment?.environment)

  const { isFetching } = data || {}
  const { values } = _data || {}

  useEffect(() => {
    initialize(DEFAULT_VALUES)
  }, [])

  const isPasswordEquals = values && values.password === values.passwordConfirm

  const onSubmit = () => isPasswordEquals ? handleSubmit() : { }

  const IS_LANDING_DADATA = REACT_APP_LANDING_DADATA === 'true'

  return (
    <form id={REGISTER_FORM_ID}>
      <Content>
        {IS_LANDING_DADATA &&
          <div className={classes.row}>
            <Field
              component={InputWithDadata}
              name='fullName'
              type='text'
              placeholder='Введите название компании или ИНН'
              label='Название компании'
              service='party'
              onSelect={payload => {
                Object.keys(payload).forEach(key => {
                  dispatch(change(REGISTER_FORM, key, payload[key]))
                })
              }}
              validate={_data && _data.anyTouched && [VALIDATIONS.required]}
              DEFAULT_VALUES={DEFAULT_VALUES}
            />
          </div>
        }
        {!IS_LANDING_DADATA &&
          <>
            <div className={classes.row}>
              <Field
                component={FormTextField}
                name='fullName'
                type='text'
                placeholder='Введите название компании'
                label='Название компании'
                validate={[VALIDATIONS.required]}
              />
            </div>
            <div className={classes.row}>
              <Field
                component={FormTextField}
                name='inn'
                type='text'
                placeholder='Введите ИНН'
                label='ИНН'
                validate={[
                  VALIDATIONS.required,
                  VALIDATIONS.inn,
                ]}
              />
              <Field
                component={FormTextField}
                name='ogrn'
                type='text'
                placeholder='Введите ОГРН/ОГРНИП'
                label='ОГРН/ОГРНИП'
                validate={[
                  VALIDATIONS.required,
                  VALIDATIONS.ogrn,
                ]}
              />
            </div>
          </>
        }
        <div className={classes.row}>
          <Field
            component={FormTextField}
            name='lastName'
            type='text'
            placeholder='Введите фамилию руководителя'
            label='Фамилия'
            validate={[VALIDATIONS.required]}
          />
          <Field
            component={FormTextField}
            name='firstName'
            type='text'
            placeholder='Введите имя руководителя'
            label='Имя'
            validate={[VALIDATIONS.required]}
          />
          <Field
            component={FormTextField}
            name='secondName'
            type='text'
            placeholder='Введите отчество руководителя'
            label='Отчество'
            validate={[VALIDATIONS.required]}
          />
        </div>
        <div className={classes.row}>
          <Field
            component={FormTextField}
            name='email'
            type='text'
            aria-describedby='emailHelp'
            placeholder='Введите E-mail'
            label='E-mail'
            validate={[
              VALIDATIONS.required,
              VALIDATIONS.email,
            ]}
          />
        </div>
        <div className={classes.row}>
          <Field
            component={FormInputPhone}
            name='phone'
            type='text'
            placeholder='Введите телефон'
            label='Телефон'
            validate={[
              VALIDATIONS.required,
              VALIDATIONS.phone,
            ]}
          />
        </div>
        <div className={classes.row}>
          <Field
            component={FormCheckboxField}
            name='vatPayer'
            label='плательщик НДС'
          />
        </div>
      </Content>
      {IS_COCONUT &&
        <>
          <br/><br/>
          <Item.Head title='Введите пароль для первого входа в систему' />
          <Content>
            <div className={classes.row}>
              <Field
                component={FormTextField}
                name='password'
                type='password'
                placeholder='Введите пароль'
                label='Пароль'
                validate={[
                  VALIDATIONS.required,
                  VALIDATIONS.password,
                ]}
              />
            </div>
            <div className={classes.row}>
              <Field
                component={FormTextField}
                name='passwordConfirm'
                type='password'
                placeholder='Подтвердите пароль'
                label='Подтверждение пароля'
                validate={[
                  VALIDATIONS.required,
                  VALIDATIONS.password,
                ]}
                errorCustom={VALIDATIONS.passwordConfirm(isPasswordEquals)}
              />
            </div>
          </Content>
        </>
      }
      <Content>
        <div className={classes.row}>
          <div className='form-group'>
            <button
              type='button'
              className={classnames('btn', classes.submitButton)}
              onClick={onSubmit}
            >
              Зарегистрироваться
              {isFetching &&
                <Overlay size='small' />
              }
            </button>
          </div>
        </div>
        {IS_COCONUT &&
          <div
            id={HELPER_ID}
            className={classes.small}
            dangerouslySetInnerHTML={{__html: HELPER_TEXT_1}}
          />
        }
      </Content>
    </form>
  )
}

export default reduxForm(REGISTER_FORM_SETTINGS)(injectSheet(styles)(Form))