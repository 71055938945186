import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import {
  getFactoringRefRateTypes,
  getFactoringRefBlockTypes,
  getFactoringRefOperationTypes,
  getFactoringRefAccountTypes,
  getFactoringRefOfferingKeys,
  getFactoringRefDocuments,
  getFactoringDuplicateSettings,
  getFactoringPriceMatrix,
  getFactoringAccountEntityTypes,
  getFactoringSuppliers,
  getFactoringSupplyRegistrySupplyTab,
} from '../../redux/Factoring/actions'
import { Search } from '../../services/api'

export const useFactoring = ({ entityType }) => {
  const dispatch = useDispatch()
  const { id } = useParams()

  const isRate = entityType === 'rate'
  const isBlock = entityType === 'block'
  const isFinancing = entityType === 'financing'
  const isFactoringContract = entityType === 'factoring_contract'
  const isSupplyContract = entityType === 'supply_contract'
  const isOperation = entityType === 'operation'
  const isProduct = entityType === 'product'
  const isAccount = entityType === 'account'
  const isSupply = entityType === 'supply'
  const isSupplyRegistry = entityType === 'supply_registry'

  const {
    blockTypes,
    rateTypes,
    operationTypes,
    accountTypes,
    offeringKeys,
  } = useSelector(state => state?.Factoring)

  useEffect(() => {
    isProduct && dispatch(getFactoringRefOfferingKeys())
    isOperation && dispatch(getFactoringRefOperationTypes())
    isRate && dispatch(getFactoringRefRateTypes())
    if (isFinancing || isSupply) {
      dispatch(getFactoringRefDocuments())
    }
    isBlock && dispatch(getFactoringRefBlockTypes())
    isAccount && dispatch(getFactoringRefAccountTypes())
    isAccount && dispatch(getFactoringAccountEntityTypes())
    isSupply && dispatch(getFactoringSuppliers())
    // eslint-disable-next-line
  }, [isProduct, isOperation, isRate, isFinancing, isBlock, isAccount, isSupply])

  useEffect(() => {
    id && isSupplyContract && dispatch(getFactoringDuplicateSettings({ entityId: id, entityType }))
    id && (isFactoringContract || isProduct) && dispatch(getFactoringPriceMatrix(id))
    id && isSupplyRegistry && dispatch(getFactoringSupplyRegistrySupplyTab(id))
  }, [id, entityType, isProduct, isSupplyContract, isFactoringContract, dispatch])

  const mapper = (item) => ({ id: item.value, name: item.name })

  const blockTypeOptions = [{ id: '', name: 'Тип блокировки' }, ...blockTypes.map(mapper)]
  const rateTypeOptions = [{ id: '', name: 'Тип ставки' }, ...rateTypes.map(mapper)]
  const operationTypeOptions = [{ id: '', name: 'Статус' }, ...operationTypes.map(mapper)]
  const accountTypeOptions = [{ id: '', name: 'Тип счёта' }, ...accountTypes.map(mapper)]
  const offeringKeyOptions = [{ id: '', name: 'Продукт' }, ...offeringKeys.map(mapper)]
  const fullyMatchedOptions = [
    { id: '', name: 'Квитовка' },
    { id: true, name: 'Сквитован полностью' },
    { id: false, name: 'Не сквитован' },
  ]
  const productTypeOptions = [
    { id: '', name: 'Продукт' },
    { id: 'REGRESS', name: 'Регресс' },
    { id: 'WO_REGRESS', name: 'Безрегресс' },
  ]

  const factoring = {
    payment_matching: {
      dataFilters: [
        { name: 'number', type: 'text', placeholder: 'Номер платежа' },
        { name: 'supplierInn', type: 'autocomplete', placeholder: 'Поставщик', payloadKey: 'INN', payloadKeyUI: 'displayName' },
        { name: 'payerInn', type: 'autocomplete', placeholder: 'Плательщик', payloadKey: 'INN', payloadKeyUI: 'displayName' },
        { name: 'dateFrom', type: 'date', placeholder: 'Дата С' },
        { name: 'dateTo', type: 'date', placeholder: 'Дата ПО' },
        { name: 'amountFrom', type: 'text', placeholder: 'Сумма ОТ' },
        { name: 'amountTo', type: 'text', placeholder: 'Сумма ДО' },
      ],
      dataItems: [],
    },
    monitoring: {
      dataFilters: [],
      dataItems: [],
    },
    counterparty: {
      dataFilters: [
        { name: 'counterparty', type: 'autocomplete', placeholder: 'Контрагент', payloadKey: 'INN', payloadKeyUI: 'displayName' },
        { name: 'pinEq', type: 'text', placeholder: 'ПИН EQ' },
      ],
      dataItems: [
        { name: 'Контрагент', key: 'counterparty' },
        { name: 'ИНН', key: 'inn' },
        { name: 'Дата проверки ДЭБ', key: 'debCheckDate', type: 'date' },
        { name: 'Сегмент', key: 'segment' },
        { name: 'Дата мониторинга', key: 'monitoringDate', type: 'date' },
        { name: 'Рейтинг', key: 'rating' },
        { name: 'Рейтинг ЦБ', key: 'ratingCb' },
        { name: 'Дата финположения', key: 'finPositionDate', type: 'date' },
        { name: 'Финположение', key: 'finPosition' },
        { name: 'Кл.подразд', key: 'keyUnit' },
        { name: 'ПИН EQ', key: 'pinEq' },
        { name: 'Дата актуализации', key: 'actualizationDate', type: 'date' },
        { name: 'Категория проблемности', key: 'problemCategory' },
        { name: 'Филиал', key: 'branch' },
        { name: 'Статус', key: 'status' },
        { name: 'ПЦ', key: 'profitCenter' },
        { name: 'Мен.развития', key: 'developmentManager' },
        { name: 'Куратор отр.', key: 'industryCurator' },
      ],
    },
    factoring_contract: {
      dataFilters: [
        {
          name: 'number',
          type: 'autocomplete',
          placeholder: 'ГД',
          payloadKey: 'number',
          payloadKeyUI: 'number',
          request: Search.getFactoringContractSuggestions,
          requestKey: 'number',
          requestSubKeys: ['displayName', 'supplierInn', 'startDate'],
        },
        {
          name: 'productType',
          type: 'select',
          options: productTypeOptions,
        },
        { name: 'supplierInn', type: 'autocomplete', placeholder: 'Поставщик', payloadKey: 'INN', payloadKeyUI: 'displayName' },
      ],
      dataItems: [
        { name: 'Договор', key: 'number' },
        { name: 'Продукт', key: 'productType' },
        { name: 'Субпродукт', key: 'subProduct' },
        { name: 'Поставщик', key: 'displayName' },
        { name: 'Действ С', key: 'startDate', type: 'date' },
        { name: 'Действ ПО', key: 'endDate', type: 'date' },
        { name: 'Статус', key: 'status' },
        { name: 'ПЦ', key: 'profitCenter' },
        { name: 'ПИН АБС', key: 'pinAbs' },
        { name: 'Примечание', key: 'note' },
        { name: 'LM_ID', key: 'lmId' },
        { name: 'Транзит', key: 'transit' },
      ],
    },
    supply_contract: {
      dataFilters: [
        {
          name: 'numberLike',
          type: 'autocomplete',
          placeholder: 'Контракт',
          payloadKey: 'number',
          payloadKeyUI: 'number',
          request: Search.getSupplyContractSuggestions,
          requestKey: 'number',
          requestSubKeys: ['number', 'startDate', 'sellerName'],
        },
        { name: 'sellerName', type: 'autocomplete', placeholder: 'Продавец', payloadKey: 'INN', payloadKeyUI: 'displayName' },
        { name: 'debtorsName', type: 'autocomplete', placeholder: 'Дебитор', payloadKey: 'INN', payloadKeyUI: 'displayName' },
      ],
      dataItems: [
        { name: 'Продавец', key: 'sellerName' },
        { name: 'Дебитор', key: 'debtorsName' },
        { name: '№ Контракта', key: 'number' },
        { name: 'Нач.дог.', key: 'startDate', type: 'date' },
        { name: 'Окон. дог.', key: 'endDate', type: 'date' },
        { name: '% финансирования', key: 'financingPercentage' },
        { name: 'Дата уведомления', key: 'signNoticeDate' },
        { name: 'Дата обратного уведомления', key: 'signRevNoticeDate' },
        { name: 'Отсрочка', key: 'defer' },
        { name: 'Примечание', key: 'notification' },
        { name: 'Срок до регресса', key: 'daysToRegress' },
        { name: 'Отложенный %', key: 'reservedPercent' },
        { name: 'Закрытый факторинг', key: 'closedFactoring' },
      ]
    },
    financing: {
      dataFilters: [
        {
          name: 'factoringContractNumber',
          type: 'autocomplete',
          placeholder: 'ГД',
          payloadKey: 'number',
          payloadKeyUI: 'number',
          request: Search.getFactoringContractSuggestions,
          requestKey: 'number',
          requestSubKeys: ['displayName', 'supplierInn', 'startDate'],
        },
        {
          name: 'supplyContractNumber',
          type: 'autocomplete',
          placeholder: 'Контракт',
          payloadKey: 'number',
          payloadKeyUI: 'number',
          request: Search.getSupplyContractSuggestions,
          requestKey: 'number',
          requestSubKeys: ['sellerName', 'startDate'],
        },
        { name: 'id', type: 'text', placeholder: 'ID запроса на финансирование' },
        { name: 'number', type: 'text', placeholder: '№ финансирования' },
        { name: 'supplyNumber', type: 'text', placeholder: '№ поставки' },
        { name: 'supplyRegistryNumber', type: 'text', placeholder: '№ Реестра' },
        { name: 'displayName', type: 'autocomplete', placeholder: 'Поставщик', payloadKey: 'INN', payloadKeyUI: 'displayName' },
        { name: 'debtorInn', type: 'autocomplete', placeholder: 'Дебитор', payloadKey: 'INN', payloadKeyUI: 'displayName' },
      ],
      dataItems: [
        { name: 'ID запроса на финансирование', key: 'id' },
        { name: 'Дубль', key: 'supplyId' },
        { name: 'Тип документа', key: 'documentType' },
        { name: '№ финансирования', key: 'number' },
        { name: '№ поставки', key: 'supplyNumber' },
        { name: '№ Реестра', key: 'supplyRegistryNumber' },
        { name: 'Дата реестра', key: 'supplyRegistryDate', type: 'date' },
        { name: 'ГД', key: 'factoringContractNumber' },
        { name: 'Поставщик', key: 'supplierName' },
        { name: 'Дебитор', key: 'debtorName' },
        { name: 'Дата', key: 'supplyDate', type: 'date' },
        { name: 'Приемка', key: 'acceptanceDate', type: 'date' },
        { name: 'Сумма', key: 'sum', type: 'amount' },
        { name: 'Валюта', key: 'currency' },
        { name: 'ЖДФ', key: 'whenToFund' },
        { name: 'Желаемая дата', key: 'financingDate' },
        { name: 'Транзит', key: 'transit' },
        { name: 'Отсрочка', key: 'deferDate', type: 'date' },
        { name: 'Просрочено дней', key: 'overdueDays' },
        { name: 'Статус', key: 'status' },
        { name: 'Ввод', key: 'createMethod' },
        { name: 'Продукт', key: 'productType' },
        { name: 'Контракт', key: 'supplyContractNumber' },
        { name: 'Оригиналы', key: 'needOriginals' },
        { name: 'Период ожидания', key: 'waitingPeriod' },
        { name: 'Регистрация', key: 'registration' },
        { name: 'Закрыто', key: 'closed' },
        { name: 'Верификация', key: 'verification' },
        { name: 'Уведомление о просрочке платежа дебитором', key: 'debtorsPaymentDelayNotification' },
        { name: 'Дата погаш фин', key: 'finPaymentDate', type: 'date' },
        { name: 'Примечание', key: 'notification' },
        { name: 'Комментарий', key: 'comment' },
        { name: 'Создал', key: 'createdBy' },
        { name: 'Создание', key: 'creation' },
        { name: 'Последний', key: 'last' },
        { name: 'Изменение', key: 'changedBy' },
        { name: 'Закрытый факторинг', key: 'closedFactoring' },
      ],
    },
    rate: {
      dataFilters: [
        {
          name: 'entityType',
          type: 'select',
          options: [
            { id: '', name: 'Тип продукта' },
            { id: 'COMMON', name: 'Общебанковский' },
            { id: 'COMPANY', name: 'Компания' },
            { id: 'FACTORING_CONTRACT', name: 'Договор факторинга' },
          ],
        },
        {
          name: 'entityId',
          type: 'autocomplete',
          placeholder: 'Владелец ставки',
          payloadKey: 'id',
          payloadKeyUI: 'displayName',
          disabledKey: 'entityType',
          showKey: 'entityType',
          showValue: 'COMPANY',
        },
        {
          name: 'entityId',
          type: 'autocomplete',
          placeholder: 'ID договора факторинга',
          payloadKey: 'id',
          payloadKeyUI: 'id',
          disabledKey: 'entityType',
          showKey: 'entityType',
          showValue: 'FACTORING_CONTRACT',
        },
        { name: 'rateType', type: 'select', options: rateTypeOptions },
        { name: 'onDate', type: 'date', placeholder: 'На дату' }, 
      ],
      dataItems: [
        { name: 'Владелец ставки', key: 'displayName' },
        { name: 'Начало действия', key: 'beginDate' , type: 'date' },
        { name: 'Конец действия', key: 'endDate', type: 'date' },
        { name: 'Тип ставки', key: 'type' },
        { name: 'Ставка %', key: 'value' },
        { name: 'Срок С', key: 'beginPeriod' },
        { name: 'Срок ПО', key: 'endPeriod' },
      ],
    },
    block: {
      dataFilters: [
        {
          name: 'factoringContractId',
          type: 'autocomplete',
          placeholder: 'ГД',
          payloadKey: 'id',
          payloadKeyUI: 'number',
          request: Search.getFactoringContractSuggestions,
          requestKey: 'number',
          requestSubKeys: ['displayName', 'supplierInn', 'startDate'],
        },
        {
          name: 'supplyContractId',
          type: 'autocomplete',
          placeholder: 'Контракт',
          payloadKey: 'id',
          payloadKeyUI: 'number',
          request: Search.getSupplyContractSuggestions,
          requestKey: 'number',
          requestSubKeys: ['sellerName', 'startDate'],
        },
        { name: 'supplier', type: 'autocomplete', placeholder: 'Поставщик', payloadKey: 'INN', payloadKeyUI: 'displayName' },
        { name: 'debtor', type: 'autocomplete', placeholder: 'Дебитор', payloadKey: 'INN', payloadKeyUI: 'displayName' },
        { name: 'type', type: 'select', options: blockTypeOptions },
      ],
      dataItems: [
        { name: 'Договор факторинга', key: 'factoringContractNumber' },
        { name: 'Договор поставки', key: 'supplyContractNumber' },
        { name: 'Поставщик', key: 'supplier' },
        { name: 'Дебитор', key: 'debtor' },
        { name: 'Поставка', key: 'supplyNumber' },
        { name: 'Тип блокировки', key: 'type' },
        { name: 'Дата блокировки С', key: 'beginDate', type: 'date' },
        { name: 'Дата блокировки ПО', key: 'endDate', type: 'date' },
        { name: 'Комментарий', key: 'description' }, 
        { name: 'Пользователь', key: 'userId' }, 
      ],
    },
    limit: {
      dataFilters: [
        { name: 'inn', type: 'autocomplete', placeholder: 'Наименовани компании', payloadKey: 'INN', payloadKeyUI: 'displayName' },
        { name: 'onDate', type: 'date', placeholder: 'Действует ПО' },
        { name: 'supplierInn', type: 'autocomplete', placeholder: 'Допустимый поставщик', payloadKey: 'INN', payloadKeyUI: 'displayName' },
        { name: 'debtorInn', type: 'autocomplete', placeholder: 'Допустимый дебитор', payloadKey: 'INN', payloadKeyUI: 'displayName' },
      ],
      dataItems: [
        { name: 'Наименовани компании', key: 'displayName' },
        { name: 'Владелец лимита дебитор', key: 'indicative', type: 'boolean' },
        { name: 'Сумма общего лимита', key: 'limitTotalAmount', type: 'amount' },
        { name: 'Сумма общего свободного лимита', key: 'limitFreeAmount', type: 'amount' },
        { name: 'Действует ПО', key: 'limitEndDate' , type: 'date' }, 
        { name: 'Сумма лимита', key: 'limitAmount', type: 'amount' },
        { name: 'Сумма свободного лимита', key: 'freelimitAmount', type: 'amount' },
        { name: 'Допустимый поставщик', key: 'suppliers' }, 
        { name: 'Допустимый  дебитор', key: 'debtors' },
        { name: 'Роль', key: 'role' },
        { name: 'ГД', key: 'factoringContractNumbers' },
        { name: 'Продукт', key: 'products' },
        { name: 'Субпродукт', key: 'subProducts' }, 
        { name: 'Оригиналы', key: 'originals' }, 
        { name: 'Категория качества', key: 'qualityCategory' },
        { name: 'Действует С', key: 'constraintBeginDate', type: 'date' }, 
        { name: 'Действует ПО', key: 'constraintEndDate', type: 'date' }, 
        { name: 'Комментарий', key: 'lastComment' },   
      ],
    },
    supply: {
      dataFilters: [
        {
          name: 'factoringContractId',
          type: 'autocomplete',
          placeholder: 'ГД',
          payloadKey: 'id',
          payloadKeyUI: 'number',
          request: Search.getFactoringContractSuggestions,
          requestKey: 'number',
          requestSubKeys: ['displayName', 'supplierInn', 'startDate'],
        },
        {
          name: 'supplyContractId',
          type: 'autocomplete',
          placeholder: 'Контракт',
          payloadKey: 'id',
          payloadKeyUI: 'number',
          request: Search.getSupplyContractSuggestions,
          requestKey: 'number',
          requestSubKeys: ['sellerName', 'startDate'],
        },
        { name: 'supplyNumber', type: 'text', placeholder: '№ поставки' },
        { name: 'supplyRegistryNumber', type: 'text', placeholder: '№ Реестра' },
        {
          name: 'displayName',
          type: 'autocomplete',
          placeholder: 'Поставщик',
          payloadKey: 'inn',
          payloadKeyUI: 'displayName',
          request: Search.getSupplierSuggestions,
          requestKey: 'inn',
          requestSubKeys: ['displayName', 'inn', 'ogrn'],
        },
        {
          name: 'debtorInn',
          type: 'autocomplete',
          placeholder: 'Дебитор',
          payloadKey: 'inn',
          payloadKeyUI: 'displayName',
          request: Search.getDebtorSuggestions,
          requestKey: 'inn',
          requestSubKeys: ['displayName', 'inn', 'ogrn'],
        },
      ],
      dataItems: [
        { name: 'ID поставки', key: 'id' },
        { name: 'Тип документа', key: 'documentType' },
        { name: '№ поставки', key: 'supplyNumber' },
        { name: '№ Реестра', key: 'supplyRegistryNumber' },
        { name: 'Дата реестра', key: 'supplyRegistryDate', type: 'date' },
        { name: 'ГД', key: 'factoringContractNumber' },
        { name: 'Поставщик', key: 'supplierName' },
        { name: 'Дебитор', key: 'debtorName' },
        { name: 'Дата', key: 'supplyDate', type: 'date' },
        { name: 'Приемка', key: 'acceptanceDate', type: 'date' },
        { name: 'Сумма', key: 'sum', type: 'amount' },
        { name: 'Валюта', key: 'currency' },
        { name: 'Отсрочка', key: 'deferDate', type: 'date' },
        { name: 'Просрочено дней', key: 'overdueDays' },
        { name: 'Статус', key: 'externalStatus' },
        { name: 'Ввод', key: 'createMethod' },
        { name: 'Продукт', key: 'productType' },
        { name: 'Контракт', key: 'supplyContractNumber' },
        { name: 'Оригиналы', key: 'needOriginals' },
        { name: 'Период ожидания', key: 'waitingPeriod' },
        { name: 'Регистрация', key: 'registration' },
        { name: 'Закрыто', key: 'closed' },
        { name: 'Верификация', key: 'verification' },
        { name: 'Уведомление о просрочке платежа дебитором', key: 'debtorsPaymentDelayNotification' },
        { name: 'Дата погаш фин', key: 'finPaymentDate', type: 'date' },
        { name: 'Последний', key: 'last' },
        { name: 'Изменение', key: 'changedBy' },
        { name: 'Закрытый факторинг', key: 'closedFactoring' },
        { name: 'Подтвержденная сумма', key: 'approvedSum', type: 'amount' },
        { name: 'Дата верификации', key: 'verificationDate', type: 'date' },
        { name: 'Долг по финансированию', key: 'asa', type: 'amount' },
        { name: 'Неоплачено Продавцом', key: 'asb', type: 'amount' },
        { name: 'Не оплачено дебитором', key: 'asc', type: 'amount' },
        { name: 'Комиссия за финансирование', key: 'asd', type: 'amount' },
        { name: 'Комиссия за ФУДЗ', key: 'ase', type: 'amount' },
        { name: 'Создал', key: 'createdBy' }, 
        { name: 'Создание', key: 'creation' },
        { name: 'Примечание', key: 'notification' },
        { name: 'Комментарий', key: 'comment' },
      ],
    },
    operation: {
      dataFilters: [
        { name: 'createDateFrom', type: 'date', placeholder: 'Дата создания ОТ' },
        { name: 'createDateTo', type: 'date', placeholder: 'Дата создания ПО' },
        { name: 'creditAccount', type: 'text', placeholder: 'Счет кредита' },
        { name: 'debitAccount', type: 'text', placeholder: 'Счет дебета' },
        { name: 'sumFrom', type: 'text', placeholder: 'Сумма проводки ОТ' },
        { name: 'sumTo', type: 'text', placeholder: 'Сумма проводки ДО' },
        { name: 'status', type: 'select', options: operationTypeOptions },     
      ],
      dataItems: [
        { name: 'Счет дебета', key: 'debitAccount' },
        { name: 'Счет кредита', key: 'creditAccount' },
        { name: 'Сумма проводки', key: 'sum', type: 'amount' },
        { name: 'Вид проводки', key: 'description' },
        { name: 'Назначение', key: 'purpose' },
        { name: 'Дата создания', key: 'createDate', type: 'date' }, 
        { name: 'Дата проводки', key: 'operationDate', type: 'date' },
        { name: 'ID АБС', key: 'absId' }, 
        { name: 'Валюта', key: 'currency' },
        { name: '№ поставки', key: 'supplyNumber' },
        { name: 'Сумма поставки', key: 'supplySum', type: 'amount' },
        { name: 'Дата поставки', key: 'supplyDate', type: 'date' }, 
        { name: 'Дата регитрации', key: 'supplyRegistration', type: 'date' },
        { name: '№ финансирования', key: 'financingNumber' },
        { name: 'Продукт', key: 'product' },
        { name: 'ГД', key: 'factoringContractNumber' },    
        { name: 'Дата ГД', key: 'factoringContractDate', type: 'date' }, 
        { name: 'Статус', key: 'status' },
        { name: 'masterOperationId', key: 'masterOperationId' },
      ],
    },
    product: {
      dataFilters: [
        { name: 'offeringKey', type: 'select', options: offeringKeyOptions },  
      ],
      dataItems: [
        { name: 'Поставщик', key: 'supplierDisplayName' },
        { name: 'Дебитор', key: 'debtorDisplayName' },
        { name: '№ ГД', key: 'factoringContractNumber' },
        { name: 'Дата ГД', key: 'factoringContractDate' },
        { name: 'Общие. Вид продукта', key: 'commonProductType' },
        { name: 'Общие. Доп ставка, если счёт в другом банке', key: 'commonOtherBankAdditionalRate' },
        { name: 'Общие. Момент оплаты комиссии', key: 'commonCommissionPayMoment' },
        { name: 'Общие. Дней до оплаты комиссии после финансирования', key: 'commonCommissionDaysAfterFinancing' },
        { name: 'Общие. День месяца оплаты комиссии', key: 'commonCommissionMonthDay' },
        { name: 'Общие. Дней до оплаты комиссии после погашения', key: 'commonCommissionDaysAfterRepayment' },
        { name: 'За финансирование. Дата окончания', key: 'finRateEndDateType' },
        { name: 'За финансирование. Учёт НДС', key: 'finNdsType' },
        { name: 'За финансирование. Вид ставки', key: 'finRateType' },
        { name: 'За финансирование. Пересчёт ставки в случае изменения Ключевой', key: 'finNeedRecalcRate' },
        { name: 'За финансирование. Дата действия ставки', key: 'finRateValidityDateType' },
        { name: 'За финансирование. Период расчета суммы: Дата начала', key: 'finAmountCalcPeriodStartDateType' },
        { name: 'За финансирование. Период расчета суммы: Дата окончания для расчета базы', key: 'finAmountCalcPeriodEndDateType' },
        { name: 'За финансирование. Определение суммы расчета', key: 'finAmountCalcType' },
        { name: 'За финансирование. Способ округления', key: 'finRoundingType' },
        { name: 'За финансирование. Кол-во знаков при округлении', key: 'finRoundingScale' },
        { name: 'За документы. Учёт НДС', key: 'docsNdsType' },
        { name: 'За документы. Дата действия ставки', key: 'docsRateValidityDateType' },
        { name: 'ФУДЗ. Вид ставки', key: 'fudzRateType' },
        { name: 'ФУДЗ. Учёт НДС', key: 'fudzNdsType' },
        { name: 'ФУДЗ. Дата действия ставки', key: 'fudzRateValidityDateType' },
        { name: 'ФУДЗ. Период расчета суммы: Дата начала', key: 'fudzAmountCalcPeriodStartDateType' },
        { name: 'ФУДЗ. Период расчета суммы: Дата окончания для расчета базы', key: 'fudzAmountCalcPeriodEndDateType' },
        { name: 'ФУДЗ. Способ округления', key: 'fudzRoundingType' },
        { name: 'ФУДЗ. Кол-во знаков при округлении', key: 'fudzRoundingScale' },
      ],
    },
    order: {
      dataFilters: [
        { name: 'principalName', type: 'autocomplete', placeholder: 'Плательшик', payloadKey: 'INN', payloadKeyUI: 'displayName' },
        { name: 'amountFrom', type: 'text', placeholder: 'Ожидаемая сумма ОТ' },
        { name: 'amountTo', type: 'text', placeholder: 'Ожидаемая сумма ДО' },
        { name: 'orderType', type: 'text', placeholder: 'Вид оплаты' },
        { name: 'number', type: 'text', placeholder: 'Номер' }, 
        { name: 'dateFrom', type: 'date', placeholder: 'Дата С' },
        { name: 'dateTo', type: 'date', placeholder: 'Дата ПО' },
      ],
      dataItems: [
        { name: 'Плательшик', key: 'principalName' },
        { name: 'Ожидаемая сума', key: 'amount', type: 'amount' }, 
        { name: 'Оплаченная сумма', key: 'matchedAmount', type: 'amount' },
        { name: 'Оплачен полностью', key: 'fullyMatched', type: 'boolean' },
        { name: 'Вид оплаты', key: 'orderType' },
        { name: 'Номер', key: 'number' },
        { name: 'Дата/время создания', key: 'orderDate', type: 'date' }, 
        { name: 'Ожидаемый остаток', key: 'unmatchedAmount', type: 'amount' }, 
      ],
    },
    account: {
      dataFilters: [
        {
          name: 'numberLike',
          type: 'autocomplete',
          placeholder: 'Номер счета',
          payloadKey: 'number',
          payloadKeyUI: 'number',
          request: Search.getFactoringAccountSuggestions,
          requestKey: 'number',
          requestSubKeys: ['entityId', 'accountType', 'entityType'],
        },
        { name: 'accountType', type: 'select', options: accountTypeOptions },
      ],
      dataItems: [
        { name: 'Номер счета', key: 'number' },
        { name: 'Тип счета', key: 'accountType' },
        { name: 'Баланс', key: 'balance', type: 'amount' },
        { name: 'Подтвержденный баланс', key: 'acceptedBalance', type: 'amount' },
        { name: 'БИК', key: 'bic' },
        { name: 'Кор. счет', key: 'corrAccount' },
        { name: 'Действующий', key: 'active', type: 'boolean' }, 
        { name: 'Внешний', key: 'external', type: 'boolean' },
        { name: 'Тип владельца', key: 'entityType' },
        { name: 'Название владельца', key: 'entityId' },
      ]
    },
    payment: {
      dataFilters: [
        { name: 'payerInn', type: 'autocomplete', placeholder: 'Плательщик', payloadKey: 'INN', payloadKeyUI: 'displayName' },
        {
          name: 'numberLike',
          type: 'autocomplete',
          placeholder: 'Номер счета плательщика',
          payloadKey: 'number',
          payloadKeyUI: 'number',
          request: Search.getFactoringAccountSuggestions,
          requestKey: 'number',
          requestSubKeys: ['entityId', 'accountType', 'entityType'],
        },
        { name: 'amountFrom', type: 'text', placeholder: 'Сумма С' },
        { name: 'amountTo', type: 'text', placeholder: 'Сумма ПО' },
        { name: 'dateFrom', type: 'date', placeholder: 'Дата С' },
        { name: 'dateTo', type: 'date', placeholder: 'Дата ПО' },
        { name: 'fullyMatched', type: 'select', options: fullyMatchedOptions },
      ],
      dataItems: [
        { name: 'Номер платежа', key: 'number' },
        { name: 'Сумма', key: 'amount', type: 'amount' },
        { name: 'Дата платежа', key: 'date', type: 'date' },
        { name: 'Назначение платежа', key: 'purpose' },
        { name: 'Сквитованная сумма', key: 'matchedAmount', type: 'amount' },
        { name: 'Не сквитованная сумма', key: 'unmatchedAmount', type: 'amount' },
        { name: 'Сквитован полностью', key: 'fullyMatched', type: 'boolean' },
        { name: 'Наименование плательщика', key: 'payerName' },
        { name: 'ИНН плательщика', key: 'payerInn' },
        { name: 'КПП плательщика', key: 'payerKpp' },
        { name: 'БИК плательщика', key: 'payerBic' },
        { name: 'Номер счета плательщика', key: 'payerAccountNumber' },
        { name: 'Наименование получателя', key: 'receiverName' },
        { name: 'ИНН получателя', key: 'receiverInn' },
        { name: 'КПП получателя', key: 'receiverKpp' },
        { name: 'БИК получателя', key: 'receiverBic' },
        { name: 'Номер счета получателя', key: 'receiverAccountNumber' },
      ],
    },
    reserve_msfo: {
      dataFilters: [],
      dataItems: [
        { name: 'Контрагент', key: 'displayName' },
        { name: 'Корзина', key: 'reserveMsfoBucket' },
        { name: 'Ставка ручн.(%)', key: 'reserveMsfoManualRate' },
        { name: 'Ставка сред.(%)', key: 'reserveMsfoAverageRate' },
        { name: 'Ставка (%)', key: 'reserveMsfoRate' },
        { name: 'EQ ID', key: 'reserveMsfoEqId' },     
      ],
    },
    reserve_rsbu: {
      dataFilters: [],
      dataItems: [
        { name: 'Контрагент', key: 'displayName' },
        { name: 'Категория клиента', key: 'reserveRsbuClientCategory' },
        { name: 'Процент резервирования', key: 'reserveRsbuReservePercent' },     
      ],
    },
    notification: {
      dataFilters: [
        { name: 'payerId', type: 'autocomplete', placeholder: 'Плательщик', payloadKey: 'id', payloadKeyUI: 'displayName' },
        { name: 'dateFrom', type: 'date', placeholder: 'Дата С' },
        { name: 'dateTo', type: 'date', placeholder: 'Дата ПО' },
      ],
      dataItems: [
        { name: 'Плательщик', key: 'displayName' },
        { name: 'Итого. Расчтная сумма', key: 'calcAmount', type: 'amount'  },
        { name: 'Итого. Сумма НДС', key: 'ndsAmount', type: 'amount' }, 
        { name: 'Итого. Сумма комиссии с НДС', key: 'amountWithNds', type: 'amount' },      
        { name: 'Номер уведомления', key: 'notificationNumber' },       
        { name: 'Дата уведомления', key: 'notificationDate' , type: 'date' },        
      ],
    },
    supply_registry: {
      dataFilters: [],
      dataItems: [
        { name: 'ID Реестра', key: 'id' }, 
        { name: '№ реестра', key: 'number' },
        { name: 'Поставщик', key: 'supplierName' },
        { name: 'Дата реестра', key: 'createDate', type: 'date' },
        { name: 'Статус', key: 'status' },
        { name: 'Ввод', key: 'createMethod' }, 
        { name: 'Количество поставок', key: 'supplyCount' },
        { name: 'Сумма поставок', key: 'supplySum', type: 'amount' },
      ],
    },
  }

  return entityType ? factoring[entityType] : null
}