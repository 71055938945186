import React, { Component } from 'react'
import injectSheet from 'react-jss'
import PropTypes from 'prop-types'
import { reduxForm, Field, initialize, getFormValues, getFormSyncErrors, change as changeFormValue } from 'redux-form'
import { connect } from 'react-redux'
import Dropdown from '../../../components/Dropdown'
import Overlay from '../../../components/Overlay'
import { Checkbox } from '../../../components/Checkbox'
import validationForm from './validationForm'
import { editUser, resetEditingUserStatus } from '../../../redux/Agents/actions'
import { setErrorContent } from '../../../redux/Error/actions'
import { Agents } from '../../../services/api'
import Error from './Error'

const styles = {
  title: {
    color: '#fff',
  },
  checkboxes: {
    marginTop: 15,
    position: 'relative',
  },
  checkbox: {
    '& > label': {
      color: '#fff',
      fontSize: 12,
      lineHeight: 20/12,
    },
  },
}

const formSettings = {
  form: 'editUser',
  validate: validationForm
}

class EditAgentForm extends Component {
  constructor(props) {
    super(props)
    
    this.state = {
      additionalGroupsState: [],
    }
  
  }

  static propTypes = {
    companyId: PropTypes.string.isRequired,
    onCloseForm: PropTypes.func.isRequired,
    editUserInfo: PropTypes.shape({
      fetching: PropTypes.bool.isRequired,
      status: PropTypes.bool.isRequired,
    }),
  }

  componentDidMount() {
    const { userId, dispatch } = this.props
    Agents.getUserData(userId)
      .then(({ user }) => {
        dispatch(initialize(formSettings.form, {
          name: `${user?.lastName} ${user?.firstName}`,
          email: user?.email,
          ismanager: user?.ismanager ? '1' : '0',
        }))
        this.setState({ additionalGroupsState: user?.additionalGroups })
      })
      .catch(err => dispatch(setErrorContent(err.message)))
  }


  componentDidUpdate(prevProps) {
    const { onCloseForm, editUserInfo: nowEditUserInfo, dispatch } = this.props
    const { editUserInfo: prevEditUserInfo } = prevProps
    if (!prevEditUserInfo.status && nowEditUserInfo.status) {
      onCloseForm()
      dispatch(resetEditingUserStatus())
    }
  }

  onEditUser = (values) => {
    const { companyId, userId, additionalGroups, dispatch, SHOW_ADDITIONAL_GROUPS } = this.props
    const { additionalGroupsState } = this.state
    const payload = {
      companyId,
      username: userId,
      ...(SHOW_ADDITIONAL_GROUPS && additionalGroups?.length > 0
        ? { additionalGroups: additionalGroupsState }
        : { }
      ),
      ...values,
    }
    dispatch(editUser(payload))
  }

  handleSubmitForm = (event) => {
    const { editUserInfo, handleSubmit } = this.props
    if (editUserInfo.fetching) {
      return
    }
    handleSubmit(this.onEditUser)(event)
  }

  handleSelectDropdownItem = (name, key) => {
    const { dispatch } = this.props
    dispatch(changeFormValue(formSettings.form, name, key))
  }

  onChangeCheckbox = (e, group) => {
    const { additionalGroupsState } = this.state
    this.setState({
      additionalGroupsState: e.target.checked
        ? [...additionalGroupsState, group]
        : additionalGroupsState.filter(item => item !== group)
    })
  }

  renderFieldItem = ({ input, meta: { touched, error }, ...rest }) => {
    return (
      <div className='form-group'>
        <input className='form-control form-control--transporent' {...input} {...rest} />
        {touched && error &&
          <Error>{error}</Error>
        }
      </div>
    )
  }

  renderDropdownItem = ({ input: { name, value }, meta: { touched, error } }) => {
    const { classes } = this.props
    return (
      <div className='form-group'>
        <Dropdown
          name={name}
          toggleClassName='btn btn-dropdown--transparent'
          defaultText='Роль'
          defaultActive={value ? +value + 1 : 0}
          list={[
            { key: '-1', value: '' },
            { key: '0', value: 'Пользователь' },
            { key: '1', value: 'Менеджер' },
          ]}
          onSelectItem={this.handleSelectDropdownItem}
          hideDefaultItem
        />
        {touched && error &&
          <Error className={classes.error}>{error}</Error>
        }
      </div>
    )
  }

  render() {
    const { editUserInfo, additionalGroups, classes, SHOW_ADDITIONAL_GROUPS } = this.props
    const { additionalGroupsState } = this.state
    return (
      <form onSubmit={this.handleSubmitForm}>
        <div className='restore-pass__title'>Изменение пользователя</div>
        <Field
          component={this.renderFieldItem}
          name='name'
          type='text'
          aria-describedby='fioHelp'
          placeholder='ФИО'
        />
        <Field
          component={this.renderFieldItem}
          name='email'
          type='email'
          aria-describedby='emailHelp'
          placeholder='Эл. почта'
        />
        <Field
          component={this.renderDropdownItem}
          name='ismanager'
        />
        {SHOW_ADDITIONAL_GROUPS &&
          <div className='form-group'>
            <div className={classes.title}>Доступные процессы пользователю:</div>
            <div className={classes.checkboxes}>
              {additionalGroups.map(({ key, name, group }) =>
                <Checkbox
                  key={key}
                  id={key}
                  className={classes.checkbox}
                  label={name}
                  onChange={(e) => this.onChangeCheckbox(e, group)}
                  checked={additionalGroupsState.indexOf(group) > -1}
                />
              )}
            </div>
          </div>
        }
        <div className='form-group' style={{marginTop: '16px'}}>
          <button type='submit' className='btn btn-block btn-white'>
            Изменить
            {editUserInfo.fetching && <Overlay size='small' />}
          </button>
        </div>
      </form>
    )
  }
}

const mapStateToProps = (state) => {
  const { Agents, Settings, Environment } = state
  return {
    editUserInfo: Agents.editUser,
    formValues: getFormValues(formSettings.form)(state),
    formErrors: getFormSyncErrors(formSettings.form)(state),
    additionalGroups: Settings?.additionalGroups || [],
    SHOW_ADDITIONAL_GROUPS: Environment?.environment?.REACT_APP_SHOW_ADDITIONAL_GROUPS === 'true',
  }
}

export default reduxForm(formSettings)(connect(mapStateToProps)(injectSheet(styles)(EditAgentForm)))