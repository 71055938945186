import React from 'react'
import { createUseStyles } from 'react-jss'
import classnames from 'classnames'
import { Alert } from '../Alert'
import { formattedNumberRub } from '../../../../utils'

const useStyles = createUseStyles({
  tableWrapper: {
    background: '#fff',
    maxHeight: 500,
    borderLeft: [1, 'solid', '#B6BCC3'],
    borderRight: [1, 'solid', '#B6BCC3'],
    borderBottom: [1, 'solid', '#B6BCC3'],
    overflow: 'auto',
  },
  table: {
    width: '100%',
    marginBottom: 0,
    '& thead': {
      '& th': {
        background: '#eee',
        textAlign: 'left',
        position: 'sticky',
        top: 0,
        zIndex: 2,
        '& + th': {
          '& > div': {
            borderLeft: [1, 'solid', '#B6BCC3'],
          },
        },
        '& > div': {
          padding: 10,
          borderTop: [1, 'solid', '#B6BCC3'],
          fontSize: 12,
          lineHeight: 18/12,
          color: '#888',
          fontWeight: 600,
          textTransform: 'uppercase',
          whiteSpace: 'nowrap',
          position: 'relative',
          '&:after': {
            content: '""',
            position: 'absolute',
            bottom: 0,
            left: 0,
            right: 0,
            height: 1,
            background: '#B6BCC3',
          },
        },
      },
    },
    '& tbody': {
      '& tr': {
        background: 'transparent',
        '&.selectedRow, &:hover': {
          background: '#0b1f35',
          cursor: 'pointer',
          '& td': {
            '& > div': {
              color: '#fff',
            },
          },
        },
        '& + tr': {
          borderTop: [1, 'solid', '#B6BCC3'],
        },
      },
      '& td': {
        padding: 0,
        whiteSpace: 'nowrap',
        textAlign: 'left',
        verticalAlign: 'top',
        '&:first-child': {
          paddingLeft: 0,
        },
        '& + td': {
          '& > div': {
            borderLeft: [1, 'solid', '#B6BCC3'],
          },
        },
        '&.colspan': {
          background: '#F3F4F5',
          padding: [10, 10, 10, 50],
          color: '#0B1F35',
          fontSize: 14,
          lineHeight: 20/14,
          fontWeight: 600,
        },
        '& > div': {
          minHeight: 40,
          padding: 10,
          fontSize: 14,
          lineHeight: 20/14,
          color: '#0B1F35',
          whiteSpace: 'nowrap',
          '&.status': {
            background: '#D5F3E2',
            padding: [6, 22],
            borderRadius: 999,
            color: '#A9B563',
            fontWeight: 600,
          },
          '&.verified': {
            background: '#FEF2E6',
            padding: 6,
            color: '#F57C00',
            fontSize: 10,
            lineHeight: 13/10,
            fontWeight: 600,
            textTransform: 'uppercase',
            borderRadius: 999,
            display: 'inline-block',
            verticalAlign: 'top',
          },
          '&.rejected': {
            background: '#FDEAE9',
            padding: 6,
            color: '#FF5C5C',
            fontSize: 10,
            lineHeight: 13/10,
            fontWeight: 600,
            textTransform: 'uppercase',
            borderRadius: 999,
            display: 'inline-block',
            verticalAlign: 'top',
          },
          '&.ellipsis': {
            // maxWidth: 70,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          },
          '&.bold': {
            fontWeight: 600,
          },
          '& > span': {
            display: 'inline-block',
            verticalAlign: 'top',
          },
          '& > svg': {
            width: 12,
            height: 12,
            marginLeft: 5,
            display: 'inline-block',
            verticalAlign: 'top',
          },
        },
      },
    },
  },
})

export const Table = ({
  id,
  dataItems,
  items,
  onOpen,
}) => {
  const classes = useStyles()
  return (
    <div className={classes.tableWrapper}>
      <table className={classes.table}>
        <thead>
          <tr>
            {dataItems.map(({ name }, index) => 
              <th key={`th-${index}`}>
                <div>{name}</div>
              </th>
            )}
          </tr>
        </thead>
        <tbody>
          {items.map((item, index) =>
            <tr
              key={`td-${index}`}
              className={classnames({ 'selectedRow': id && item?.id?.toString() === id?.toString() })}
              onClick={() => onOpen && onOpen(item.id)}
            >
              {dataItems.map(({ key, type }, index) =>
                <td key={index}>
                  <div>
                    {key === 'externalStatus'
                      ? <Alert type='success'>{item[key] || '-'}</Alert>
                      : !item[key] && type !== 'boolean'
                        ? '-'
                        : type === 'date'
                          ? new Date(item[key]).toLocaleDateString('ru-RU')
                          : type === 'amount'
                            ? formattedNumberRub(item[key])
                            : type === 'boolean'
                              ? item[key] === true
                                ? key === 'fullyMatched'
                                  ? <Alert type='success'>Да</Alert>
                                  : 'Да'
                                : key === 'fullyMatched'
                                  ? <Alert type='danger'>Нет</Alert>
                                  : 'Нет'
                              : typeof item[key] === 'object'
                                ? JSON.stringify(item[key])
                                : item[key]
                      }
                  </div>
                </td>
              )}
            </tr>
          )}
        </tbody>
      </table>
    </div>
  )
}