import React, { Component } from 'react'
import injectSheet from 'react-jss'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link, NavLink, withRouter } from 'react-router-dom'
import ProgressStatistic from '../../components/ProgressStatistic'
import Banner from '../../components/Banner'
import UserMenu from '../../components/UserMenu'
import { Icon } from '../../layout'
import { logoutUser } from '../../redux/User/actions'
import { getTasksList, clearAllFilters } from '../../redux/Tasks/actions'
import { fetchWidgetData } from '../../redux/Statistics/actions'
import { IS_COCONUT_FACTORING, IS_POMELO } from '../../config'
import { ModalAlternative } from '../ModalAlternative/ModalAlternative'
import { Reports } from '../../components/Reports'

const styles = {
  button: {
    marginTop: 8,
    position: 'relative',
    '& a': {
      borderRadius: 16,
      color: '#242232',
      fontSize: 16,
      lineHeight: 1.5,
      fontWeight: 600,
      display: 'block',
      '&:hover': {
        color: '#242232'
      },
    },
    '& svg': {
      width: 14,
      height: 14,
      marginRight: 16,
      fill: '#242232',
      display: 'inline-block',
      verticalAlign: 'middle',
      // opacity: 0.48,
    },
    '& span': {
      display: 'inline-block',
      verticalAlign: 'middle',
    },
  },
  edo: {
    marginBottom: 15,
    textAlign: 'center',
    '& a': {
      textDecoration: 'underline',
    },
  },
}

class Sidebar extends Component {
  static propTypes = {
    name: PropTypes.string,
    isManager: PropTypes.bool,
    widget: PropTypes.shape({
      items: PropTypes.object.isRequired,
      sum: PropTypes.oneOfType([PropTypes.oneOf([null]), PropTypes.number]),
      noItems: PropTypes.bool.isRequired
    }).isRequired,
    dispatch: PropTypes.func.isRequired
  }
  static defaultProps = {
    name: '',
    isManager: false,
  }

  state = {
    openModal: false,
  }

  onModalReportHandler = () => {
    this.setState({openModal: !this.state.openModal})
  }

  componentDidMount() {
    this.props.dispatch(fetchWidgetData())
  }

  onLogout = async () => {
    const { settings, dispatch } = this.props
    await dispatch(logoutUser(settings.authType))
  }

  onGetTasksList = async () => {
    const { dispatch } = this.props
    dispatch(clearAllFilters())
    await dispatch(getTasksList({}))
  }

  renderStatsWidget() {
    const { widget, settings } = this.props

    return widget.noItems
      ? null
      : <div className='fr-sidebar-bm__statistics-cont progress-statistic'>
          {settings.statusItems.map(({
            key,
            text,
            className,
          }, index) => typeof widget.items[key] !== 'undefined'
            ? <ProgressStatistic
                key={index}
                count={widget.items[key].count}
                sum={widget.sum}
                text={text}
                className={className}
              />
            : null
          )}
        </div>
  }

  render() {
    const {
      classes,
      name,
      widgets,
      isManager,
      isClient,
      isRko,
      isNotVerifiedUser,
      noClients,
      enableStatsReport,
      reports,
      IS_FACTORING_ENABLED,
      SHOW_BANNER,
      SHOW_REPORTS,
      SHOW_STATS,
      SIGNERS_DOCS_URL,
    } = this.props

    const isCalc = widgets?.bgtc

    return (
      <section className='fr-sidebar'>
        <Link className='fr-sidebar__logo' to='/' />
        <div className='fr-sidebar__menu'>
          {!isRko &&
            <NavLink to='/tasks' activeClassName='active' onClick={this.onGetTasksList}>
              <span className='icon icon-ok' />
              <span>Заявки</span>
            </NavLink>
          }
          {!noClients && !isClient && !isRko && !isNotVerifiedUser &&
            <NavLink to='/clients' activeClassName='active'>
              <span className='icon icon-user' />
              <span>Клиенты</span>
            </NavLink>
          }
          {isManager && 
            <NavLink to='/agents' activeClassName='active'>
              <span className='icon icon-user' />
              <span>Агенты</span>
            </NavLink>
          }
          {isManager && SHOW_REPORTS && 
            <Link onClick={this.onModalReportHandler}>
              <span className='icon icon-file' />
              <span>Отчеты</span>
            </Link>
          }
          {isCalc &&
            <div className={classes.button}>
              <NavLink to='/calc'>
                <Icon iconName='calculator' />
                <span>Калькулятор</span>
              </NavLink>
            </div>
          }
          {IS_FACTORING_ENABLED &&
            <>
              <NavLink to='/factoring/supply' activeClassName='active'>
                <span className='icon icon-ok' />
                <span>Поставки</span>
              </NavLink>
              <NavLink to='/factoring/supply_registry' activeClassName='active'>
                <span className='icon icon-ok' />
                <span>Реестры поставок</span>
              </NavLink>
            </>
          }
          {reports &&
            <NavLink to='/factoring/reports' activeClassName='active'>
              <span className='icon icon-ok' />
              <span>Отчёты</span>
            </NavLink>
          }
          {SIGNERS_DOCS_URL &&
            <a
              href={SIGNERS_DOCS_URL}
              target='_blank'
              rel='noopener noreferrer'
              download
            >
              <span className="icon icon-file"/>
              <span>
                {IS_POMELO
                  ? 'Документы Банка'
                  : 'Доверенности на подписантов'
                }
              </span>
            </a>
          }
        </div>
        {this.state.openModal &&
          <ModalAlternative height='auto' onClose={this.onModalReportHandler}>
            <Reports url={{}} onCancel={this.onModalReportHandler} />
          </ModalAlternative>
        }
        {SHOW_BANNER &&
          <Banner>
            <p>Сделайте заявку на превышение комиссии!</p>
            <p>Расчетная комиссия</p>
            <p>НЕ предусматривает агентского вознаграждения!</p>
          </Banner>
        }
        <div className='fr-sidebar-bm'>
          {IS_COCONUT_FACTORING && (isClient || isNotVerifiedUser) &&
            <div className={classes.edo}>
              <a href='/files/Reglament_EDO.pdf' target='_blank'>Регламент ЭДО</a>
            </div>
          }
          {!isRko && !isNotVerifiedUser && !isClient && SHOW_STATS &&
            <div className='fr-sidebar-bm__statistics'>
              {this.renderStatsWidget()}
              {
                (isManager && enableStatsReport) &&
                <div className='fr-sidebar-bm__statistics-btn' style={{margin: '5px 0'}}>
                  <Link to='?show-report'>Отчёт</Link>
                </div>
              }
              <div className='fr-sidebar-bm__statistics-btn'>
                <Link to='?show-statistic'>Смотреть статистику</Link>
              </div>
            </div>
          }
          <UserMenu name={name} onLogout={this.onLogout} />
        </div>
      </section>
    )
  }
}

const mapStateToProps = ({
  User,
  Statistics,
  Settings,
  Environment,
}) => {
  return {
    enableStatsReport: Settings.enableStatsReport,
    settings: User.settings,
    name: User.fullname,
    isManager: User.ismanager,
    isRko: User.isrko,
    isNotVerifiedUser: User.notVerifiedUser,
    widgets: Settings.widgets,
    widget: Statistics.widget,
    isClient: User.isclient,
    noClients: User.noClients,
    reports: Settings.reports,
    IS_FACTORING_ENABLED: Environment?.environment?.REACT_APP_FACTORING_ENABLED === 'true',
    SHOW_BANNER: Environment?.environment?.REACT_APP_SHOW_BANNER === 'true',
    SHOW_REPORTS: Environment?.environment?.REACT_APP_SHOW_REPORTS === 'true',
    SHOW_STATS: Environment?.environment?.REACT_APP_SHOW_STATS === 'true',
    SIGNERS_DOCS_URL: Environment?.environment?.REACT_APP_SIGNERS_DOCS_URL,
  }
}

export default withRouter(connect(mapStateToProps)(injectSheet(styles)(Sidebar)))