import React, { useEffect } from 'react'
import { Route, Switch, Redirect, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import LayoutBase from './containers/Layout'
import Agents from './pages/Agents'
import { Calculator } from './pages/Calculator'
import Clients from './pages/Clients'
import LoginBase from './pages/Login'
import NotFound from './pages/NotFound'
import Register from './pages/Register'
import { RegisterToken } from './pages/RegisterToken'
import Tasks from './pages/Tasks'
import Verify from './pages/Verify'
import ErrorPage from './pages/ErrorPage'
import { TasksRko } from './pages/TasksRko'
import { FactoringViewPage, FactoringReportsPages } from './pages/FactoringPages'
import ErrorState from './containers/ErrorState'
import { getEnvironment } from './redux/Environment/actions'

export const Routes = ({ isKeycloakAuthType }) => {
  const dispatch = useDispatch()
  const location = useLocation()

  const { errorMessage } = location?.state || {}

  const { isrko } = useSelector(state => state?.User || {})

  useEffect(() => {
    dispatch(getEnvironment())
  }, [dispatch])

  const TasksComponent = errorMessage
    ? ErrorState
    : isrko
      ? TasksRko
      : Tasks

  const Login = LoginBase
  const Layout = LayoutBase

  const ToTasks = () => <Redirect to='/tasks' />

  return (
    <Switch>
      {errorMessage
        ? <Route exact path='/' render={() => <ErrorState errorMessage={errorMessage} />} />
        : isKeycloakAuthType
          ? <Layout exact path='/' component={ToTasks} />
          : <Route exact path='/' component={Login} />
      }
      <Route exact path='/verify/:token' component={Verify} />
      <Route exact path='/register/:token' component={RegisterToken} />
      <Route exact path='/register' component={Register} />
      <Route exact path='/error' component={ErrorPage} />
      <Layout path='/tasks/:id' component={TasksComponent} />
      <Layout path='/tasks' component={TasksComponent} />
      <Layout path='/clients/:id' component={Clients} />
      <Layout path='/clients' component={Clients} />
      <Layout path='/agents/:agent/users/new' component={Agents} />
      <Layout path='/agents/:agent/users/:user' component={Agents} />
      <Layout path='/agents/:agent/users' component={Agents} />
      <Layout path='/agents' component={Agents} />
      <Layout exact path='/calc' component={Calculator} />
      <Layout exact path='/factoring/reports' component={FactoringReportsPages} />
      <Layout exact path='/factoring/:entityType' component={FactoringViewPage} fullWidth />
      <Layout exact path='/factoring/:entityType/:id' component={FactoringViewPage} fullWidth />
      <Layout component={NotFound} isNotFound />
    </Switch>
  )
}