import React from 'react'
import { createUseStyles } from 'react-jss'
import Modal from '../index'
import { Copy } from '../../../components/Copy/Copy'

const useStyles = createUseStyles({
  content: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  right: {
    display: 'flex'
  },
  left: {
    display: 'flex',
    flexDirection: 'column'
  },
  item: {
    display: 'inline-block',
    verticalAlign: 'top',
    '& + &': {
      marginLeft: 50,
    },
  },
  title: {
    fontSize: 14,
  },
})

export const Principal = ({
  name,
  inn,
  kpp,
  ogrn,
  isFactoring,
}) => {
  const classes = useStyles()
  const items = [
    { title: 'ИНН', value: inn },
    { title: 'КПП', value: kpp },
    { title: 'ОГРН', value: ogrn },
  ]
  return (
    <Modal.Block iconName='shield' title={isFactoring ? 'Поставщик' : 'Принципал'}>
      <Modal.Content className={classes.content}>
        <div className={classes.left}>
          <Copy valueToCopy={name} successText={'Наименование принципала скопировано!'}
                customStyles={{position: 'relative !important'}}
                customWrapperStyles={{ display: 'flex', gap: '10px', justifyContent: 'flex-start', alignItems: 'center'}}>
            <Modal.Value>{name}</Modal.Value></Copy>
          <Modal.Title>Наименование {isFactoring ? 'поставщика' : 'принципала'}</Modal.Title>
        </div>
        <div className={classes.right}>
            {items.map(({ title, value }, index) =>
              <div className={classes.item} key={index}>
                <Copy valueToCopy={value} successText={`${title} скопирован`} customStyles={{ position: 'absolute', top: '10px', right: '10px'}}><Modal.Value>{value}</Modal.Value></Copy>
                <Modal.Title className={classes.title}>{title}</Modal.Title>
              </div>
            )}
          </div>
      </Modal.Content>
    </Modal.Block>
  )
}