import React, { Component } from 'react'
import injectSheet from 'react-jss'
import PropTypes from 'prop-types'
import { 
  reduxForm, 
  Field, 
  getFormValues, 
  getFormSyncErrors, 
  change as changeFormValue, 
} from 'redux-form'
import { connect } from 'react-redux'
import { addUser, resetAddingUserStatus, resetAddUserError } from '../../../redux/Agents/actions'
import Dropdown from '../../../components/Dropdown'
import Overlay from '../../../components/Overlay'
import { Checkbox } from '../../../components/Checkbox'
import validationForm from './validationForm'
import Error from './Error'

const styles = {
  title: {
    color: '#fff',
  },
  checkboxes: {
    marginTop: 15,
    position: 'relative',
  },
  checkbox: {
    '& > label': {
      color: '#fff',
      fontSize: 12,
      lineHeight: 20/12,
    },
  },
}

const formSettings = {
  form: 'newUser',
  validate: validationForm,
}

class NewAgentForm extends Component {
  constructor(props) {
    super(props)
    
    this.state = {
      additionalGroupsState: [],
    }
  
  }

  static propTypes = {
    companyId: PropTypes.string.isRequired,
    onCloseForm: PropTypes.func.isRequired,
    addUserInfo: PropTypes.shape({
      fetching: PropTypes.bool.isRequired,
      status: PropTypes.bool.isRequired,
    }),
  }

  componentDidUpdate(prevProps) {
    const { onCloseForm, addUserInfo: nowAddUserInfo, resetAddingUserStatus } = this.props
    const { addUserInfo: prevAddUserInfo } = prevProps

    if (!prevAddUserInfo.status && nowAddUserInfo.status) {
      onCloseForm()
      resetAddingUserStatus()
    }
  }

  onAddUser = (values) => {
    const { companyId, additionalGroups, addUser, SHOW_ADDITIONAL_GROUPS } = this.props
    const { additionalGroupsState } = this.state
    const payload = {
      companyId,
      ...(SHOW_ADDITIONAL_GROUPS && additionalGroups?.length > 0
        ? { additionalGroups: additionalGroupsState }
        : { }
      ),
      ...values,
    }
    addUser(payload)
  }

  onSubmitForm = (event) => {
    const { addUserInfo, handleSubmit } = this.props
    if (addUserInfo.fetching) {
      return
    }
    handleSubmit(this.onAddUser)(event)
  }

  onSelect = (name, key) => this.props.changeFormValue(formSettings.form, name, key)
  onResetError = (name) => this.props.resetAddUserError(name)

  onChangeCheckbox = (e, group) => {
    const { additionalGroupsState } = this.state
    this.setState({
      additionalGroupsState: e.target.checked
        ? [...additionalGroupsState, group]
        : additionalGroupsState.filter(item => item !== group)
    })
  }

  renderFieldItem = ({ input, meta: { touched, error }, ...rest }) => {
    const { addUserInfo } = this.props
    const hasError = addUserInfo.error && input.name && addUserInfo.error[input.name]
    return (
      <div className='form-group'>
        <input
          className='form-control form-control--transporent'
          onKeyUp={() => hasError 
            ? this.onResetError(input.name) 
            : () => {}
          }
          {...input}
          {...rest}
        />
        {touched && error &&
          <Error>{error}</Error>
        }
      </div>
    )
  }

  renderDropdownItem = ({ input: { name, value }, meta: { touched, error } }) => {
    const { classes } = this.props
    return (
      <div className='form-group'>
        <Dropdown
          name={name}
          toggleClassName='btn btn-dropdown--transparent'
          defaultText='Роль'
          defaultActive={value ? +value + 1 : 0}
          list={[
            { key: '-1', value: '' },
            { key: '0', value: 'Пользователь' },
            { key: '1', value: 'Менеджер' },
          ]}
          hideDefaultItem
          onSelectItem={this.onSelect}
        />
        {touched && error && 
          <Error className={classes.error}>{error}</Error>
        }
      </div>
    )
  }

  render() {
    const { addUserInfo, additionalGroups, classes, SHOW_ADDITIONAL_GROUPS } = this.props
    const INPUTS = [
      {
        name: 'name',
        type: 'text',
        placeholder: 'ФИО',
        'aria-describedby': 'fioHelp',
        component: this.renderFieldItem,
      },
      {
        name: 'email',
        type: 'email',
        placeholder: 'Эл. почта',
        'aria-describedby': 'emailHelp',
        component: this.renderFieldItem,
      },
      {
        name: 'ismanager',
        component: this.renderDropdownItem,
      },
    ]
    return (
      <form onSubmit={this.onSubmitForm}>
        <div className='restore-pass__title'>Добавление пользователя</div>
        {INPUTS.map((item, index) =>
          <div className='form-group' key={index}>
            <Field {...item} />
            {addUserInfo.error && item.name && addUserInfo.error[item.name] && 
              <Error>{addUserInfo.error[item.name]}</Error>
            }
          </div>
        )}
        {SHOW_ADDITIONAL_GROUPS &&
          <div className='form-group'>
            <div className={classes.title}>Доступные процессы пользователю:</div>
            <div className={classes.checkboxes}>
              {additionalGroups.map(({ key, name, group }) =>
                <Checkbox
                  id={key}
                  key={key}
                  className={classes.checkbox}
                  label={name}
                  onChange={(e) => this.onChangeCheckbox(e, group)}
                />
              )}
            </div>
          </div>
        }
        <div className='form-group'>
          <button type='submit' className='btn btn-block btn-white' disabled={addUserInfo.fetching}>
            {addUserInfo.fetching 
              ? <Overlay size='small' />
              : <>Сохранить</>
            }
          </button>
        </div>
      </form>
    )
  }
}

const mapStateToProps = (state) => {
  const { Agents, Settings, Environment } = state
  return {
    addUserInfo: Agents?.addUser,
    formValues: getFormValues(formSettings.form)(state),
    formErrors: getFormSyncErrors(formSettings.form)(state),
    additionalGroups: Settings?.additionalGroups || [],
    SHOW_ADDITIONAL_GROUPS: Environment?.environment?.REACT_APP_SHOW_ADDITIONAL_GROUPS === 'true',
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
	return {
    addUser: (payload) => {
      dispatch(addUser(payload))
    },
    resetAddingUserStatus: () => {
      dispatch(resetAddingUserStatus())
    },
    resetAddUserError: (name) => {
      dispatch(resetAddUserError(name))
    },
    changeFormValue: (form, name, key) => {
      dispatch(changeFormValue(form, name, key))
    },
	}
}

export default reduxForm(formSettings)(connect(mapStateToProps, mapDispatchToProps)(injectSheet(styles)(NewAgentForm)))