import React, { Component } from 'react'
import { connect } from 'react-redux'
import classnames from 'classnames'
import injectSheet from 'react-jss'
import Container from './Container'
import Content from './Content'
import Grid from './Grid'
import Justify from './Justify'
import Header from './Header'
import Item from './Item/index'
import ItemGrey from './ItemGrey/index'
import Error from './Error'
import Overlay from './../../components/Overlay'
import { getVerify } from './../../redux/Verify/actions'
import { THEME_COLOR, adaptive_1200, adaptive_1400 } from './../../config'
import { plural, formattedNumber, formattedDate } from './../../utils'
import { Copy } from '../../components/Copy/Copy'

const styles = {
  root: {
    paddingTop: 30,
    fontFamily: 'Montserrat',
    [adaptive_1200]: {
      paddingTop: 0,
    },
  },
  header: {
    backgroundColor: THEME_COLOR,
  },
  icon: {
    fill: THEME_COLOR,
  },
  publish: {
    position: 'absolute',
    top: 0,
    right: 0,
    textAlign: 'right',
  },
  bold: {
    fontWeight: 'bold',
  },
  theme: {
    color: THEME_COLOR,
  },
  relative: {
    position: 'relative',
  },
  pr30: {
    paddingRight: 30,
  },
  pr130: {
    paddingRight: 130,
  },
  pb6: {
    paddingBottom: 6,
  },
  mt20: {
    marginTop: 20,
  },
  gray: {
    backgroundColor: 'rgba(238, 238, 238, 0.5)',
    marginTop: 24,
    paddingTop: 14,
    paddingBottom: 14,
  },
  block: {
    [adaptive_1400]: {
      display: 'block',
    },
  },
  justify: {
    [adaptive_1400]: {
      flexWrap: 'wrap',
    },
  },
  helper: {
    [adaptive_1200]: {
      width: '100%',
    },
    '& + &': {
      [adaptive_1200]: {
        marginTop: 16,
      },
    },
  },
  helper2: {
    '& + &': {
      [adaptive_1400]: {
        marginTop: 16,
      },
    },
  },
}

class Verify extends Component {
  componentDidMount() {
    this.props.getVerify()
    document.body.style.backgroundColor = '#E2E2E2'
  }

  componentWillUnmount() {
    document.body.style.backgroundColor = ''
  }

  render() {
    const { verify, classes } = this.props
    const { isFetching, data, error } = verify
    const {
      orderNumber = '—',
      orderStatus = '—',
      // orderType,
      bankGuaranteeTypeName = '—',
      federalLawName = '—',
      beneficiaries = [],
      product = {},
      purchase = {},
      principal = {},
      lot = {},
    } = data

    const durationDays = product.durationDays || 0

    const content = {
      header: [
        {
          iconName: 'guarantee',
          key: 'Номер',
          value: orderNumber || '—',
        },
        {
          iconName: 'shield-filled',
          key: 'Статус',
          value: orderStatus || '—',
        },
      ],
      items: [
        {
          title: 'Информация по аукциону',
          iconName: 'info',
          published_at: product.provisionedDate ? formattedDate(product.provisionedDate) : '—',
          items: [
            { key: 'ФЗ', value: federalLawName || '—' },
            { key: 'Предмет контракта', value: purchase.subject || '—' },
            { key: 'Наименование площадки', value: purchase.marketPlace || '—' },
            { key: 'Тип закупки', value: purchase.tenderType || '—' },
          ],
          custom: {
            key: 'НМЦ',
            value: lot.startAmount ? formattedNumber(lot.startAmount) : '—',
          },
        },
        {
          title: 'Бенефициар',
          iconName: 'customer',
          items: [
            { key: 'Наименование бенефициара', value: (beneficiaries[0] && beneficiaries[0].displayName) || '—' },
            { key: 'ИНН бенефициара', value: (beneficiaries[0] && beneficiaries[0].INN) || '—' },
            { key: 'ОГРН бенефициара', value: (beneficiaries[0] && beneficiaries[0].OGRN) || '—' },
            { key: 'КПП бенефициара', value: (beneficiaries[0] && beneficiaries[0].KPP) || '—' },
          ],
        },
        {
          title: 'Принципал',
          iconName: 'provider',
          items: [
            { key: 'Наименование принципала', value:  <Copy valueToCopy={principal.displayName} successText={'Наименование принципала скопировано!'}>{principal.displayName}</Copy> || '—'  },
            { key: 'ИНН принципала', value: principal.INN || '—' },
            { key: 'ОГРН принципала', value: principal.OGRN || '—' },
            { key: 'КПП принципала', value: principal.KPP || '—' },
          ],
        },
      ]
    }

    if (isFetching) {
      return <Overlay />
    }

    if (error) {
      return <Error />
    }
 
    return (
      <section className={classes.root}>
        <Container>
          <Header items={content.header} className={classes.header} />
          <Item>
            <Item.Head title='Банковская гарантия' />
            <Content>
              <ItemGrey>
                <Content>
                  <Justify className={classes.block}>
                    <div className={classes.helper2}>
                      <ItemGrey.Block>
                        <ItemGrey.Key>Тип</ItemGrey.Key>
                        <Item.Value>{bankGuaranteeTypeName || '—'}</Item.Value>
                      </ItemGrey.Block>
                    </div>
                    <div className={classes.helper2}>
                      <ItemGrey.Block>
                        <ItemGrey.Key>Номер закупки</ItemGrey.Key>
                        <ItemGrey.Value>{purchase.number || '—'}</ItemGrey.Value>
                      </ItemGrey.Block>
                      <ItemGrey.Block>
                        <ItemGrey.Key>Номер лота</ItemGrey.Key>
                        <ItemGrey.Value>{lot.number || '—'}</ItemGrey.Value>
                      </ItemGrey.Block>
                    </div>
                  </Justify>
                </Content>
              </ItemGrey>
            </Content>
            <Content className={classes.mt20}>
              <Justify className={classes.justify}>
                <Grid.Col_md_3>
                  <ItemGrey iconName='shield'>
                    <div>
                      <ItemGrey.Key>Сумма</ItemGrey.Key>
                      <ItemGrey.Value className={classes.bold}>
                        {lot.bgAmount ? `${formattedNumber(lot.bgAmount)} ₽` : '—'}
                      </ItemGrey.Value>
                    </div>
                  </ItemGrey>
                </Grid.Col_md_3>
                <Grid.Col_md_6>
                  <ItemGrey iconName='clock'>
                    <Justify>
                      <div className={classes.helper}>
                        <ItemGrey.Key>Действует с</ItemGrey.Key>
                        <ItemGrey.Value className={classes.bold}>
                          {product.startDate ? formattedDate(product.startDate) : '—'}
                        </ItemGrey.Value>
                      </div>
                      <div className={classes.helper}>
                        <ItemGrey.Key>Действует по</ItemGrey.Key>
                        <ItemGrey.Value className={classes.bold}>
                          {product.endDate ? formattedDate(product.endDate) : '—'}
                        </ItemGrey.Value>
                      </div>
                      <div className={classes.helper}>
                        <ItemGrey.Key>Срок действия</ItemGrey.Key>
                        <ItemGrey.Value className={classnames(classes.bold, classes.theme, classes.pr30)}>
                          {durationDays ? `${durationDays} ${plural(durationDays, 'день', 'дня', 'дней')}` : '—'}
                        </ItemGrey.Value>
                      </div>
                    </Justify>
                  </ItemGrey>
                </Grid.Col_md_6>
                <Grid.Col_md_3>
                  <ItemGrey iconName='purse'>
                    <div>
                      <Item.Key>Предложенная цена контракта</Item.Key>
                      <ItemGrey.Value className={classes.bold}>
                        {lot.finalAmount ? formattedNumber(lot.finalAmount) : '—'} ₽
                      </ItemGrey.Value>
                    </div>
                  </ItemGrey>
                </Grid.Col_md_3>
              </Justify>
            </Content>
          </Item>
          {content.items.map(({
            title,
            iconName,
            published_at,
            items,
            custom,
          }, index) => {
            const hasCustom = custom && custom.key && custom.value
            
            return (
              <Item key={`${title}-${index}`} className={classnames({ [classes.pb6]: custom })}>
                <Item.Head
                  className={classes.icon}
                  iconName={iconName} 
                  title={title} 
                />
                <Content>
                  <div className={classes.relative}>
                    {published_at &&
                      <div className={classes.publish}>
                        <Item.Key>Дата публикации</Item.Key>
                        <Item.Value>{published_at}</Item.Value>
                      </div>
                    }
                    {items.map(({ key, value }, index) =>
                      <Item.Block key={`${value}-${index}`} className={classnames({ 
                        [classes.pr130]: hasCustom && index === 0, 
                      })}>
                        <Item.Key>{key}</Item.Key>
                        <Item.Value>{value}</Item.Value>
                      </Item.Block>
                    )}
                  </div>
                </Content>
                {hasCustom && 
                  <Item.Block className={classes.gray}>
                    <Content>
                      <ItemGrey.Key>{custom.key}</ItemGrey.Key>
                      <ItemGrey.Value className={classes.bold}>{custom.value} ₽</ItemGrey.Value>
                    </Content>
                  </Item.Block>
                }
              </Item>
            )}
          )}
        </Container>
      </section>
    )
  }
}

const mapStateToProps = ({ Verify }) => {
	return {
		verify: Verify,
	}
}

const mapDispatchToProps = (dispatch, ownProps) => {
  const { token } = ownProps.match.params

  return {
    getVerify: () => {
      dispatch(getVerify(token))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(injectSheet(styles)(Verify))